import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';

const NavBarAtas = (props) => {
    const { translations, changeLanguage, language } = useLanguage();
    const navbarTranslations = translations?.navbar || {};

    const [selectedPage, setSelectedPage] = useState(0);
    const [showLoginButton, setShowLoginButton] = useState(true);

    useEffect(() => {
        setSelectedPage(props.page);
        setShowLoginButton(props.showButton);
    }, [props.page, props.showButton]);

    useEffect(() => {
        // Load saved language from localStorage
        const savedLanguage = localStorage.getItem('selectedLanguage');
        if (savedLanguage && savedLanguage !== language) {
            changeLanguage(savedLanguage); // Set context language
        }

        // Ensure navbar script is loaded
        const script = document.createElement('script');
        script.src = '/js/collapsable-navbar-igahp.js';
        script.async = true;
        document.body.appendChild(script);
    }, [language, changeLanguage]);

    const handleLanguageChange = (lang) => {
        changeLanguage(lang); // Update context
        localStorage.setItem('selectedLanguage', lang); // Save language preference
    };

    return (
        <nav className="igahp-navbar-content">
            <div className="igahp-navbar">
                <div className="igahp-navbar-left">
                    <a href="/">
                        <img src="/logo_kementerian_pkp.svg" alt="IGAHP" id="logo-igahp" />
                    </a>
                </div>
                <div className="igahp-navbar-right">
                    <div className="igahp-navbar-icon">
                        <img src="/ic_menu.svg" id="icon-burger" alt="Menu" />
                    </div>
                    <div className="igahp-navbar-menu">
                        <ul>
                            <li className={`igahp-navbar-item ${selectedPage == 2 ? "selected" : ""}`}>
                                <a href="/product">{navbarTranslations.product || "Produk"}</a>
                                <div className="navbar-selected-line" />
                            </li>
                            <li className={`igahp-navbar-item ${selectedPage == 3 ? "selected" : ""}`}>
                                <a href="/sertifikasi">{navbarTranslations.certification || "Sertifikasi"}</a>
                                <div className="navbar-selected-line" />
                            </li>
                            <li className={`igahp-navbar-item ${selectedPage == 4 ? "selected" : ""}`}>
                                <a href="/regulasi">{navbarTranslations.regulation || "Regulasi"}</a>
                                <div className="navbar-selected-line" />
                            </li>
                            <li className={`igahp-navbar-item ${selectedPage == 5 ? "selected" : ""}`}>
                                <a href="/comingsoon">{navbarTranslations.contact || "Kontak"}</a>
                                <div className="navbar-selected-line" />
                            </li>
                            <li className={`igahp-navbar-item ${selectedPage == 6 ? "selected" : ""}`}>
                                <a href="/aplikasi">{navbarTranslations.mobileApp || "Mobile Apps"}</a>
                                <div className="navbar-selected-line" />
                            </li>

                            <li className="igahp-navbar-item">
                                
                                <div className="select-language-border">
                                    <div className="toggle-language">
                                        <span
                                            onClick={() => changeLanguage('id')}
                                            className={language === 'id' ? 'selected-language' : ''}
                                        >
                                            ID
                                        </span>
                                        <span
                                            onClick={() => changeLanguage('en')}
                                            className={language === 'en' ? 'selected-language' : ''}
                                        >
                                            EN
                                        </span>
                                    </div>
                                </div>
                            </li>
                            <li
                                className={
                                    showLoginButton
                                        ? 'igahp-navbar-button'
                                        : 'igahp-navbar-button hidden'
                                }
                            >
                                <a href="/login" className="igahp-m-medium">
                                    <div className="btn-green-solid-pressed">
                                        {navbarTranslations.login}
                                        <img src="/ic_arrow_right_white.svg" alt="" />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="navbar-menu-expanded" id="navbar-menu-expanded">
                <div className="igahp-navbar-menu-expanded">
                    {/* <a href=""><div className="igahp-l-medium black-text">IGAHP</div></a>
                        <div className="igahp-divider"></div> */}
                    <a href="/product">
                        <div className={`igahp-l-medium ${selectedPage == 2 ? "neutral-60-text" : "black-text"}`}>
                            {navbarTranslations.product || "Produk"}
                        </div>
                    </a>
                    <div className="igahp-divider"></div>
                    <a href="/comingsoon">
                    <div className={`igahp-l-medium ${selectedPage == 3 ? "neutral-60-text" : "black-text"}`}>
                            {navbarTranslations.certification || "Sertifikasi"}
                        </div>
                    </a>
                    <div className="igahp-divider"></div>
                    <a href="/regulasi">
                    <div className={`igahp-l-medium ${selectedPage == 4 ? "neutral-60-text" : "black-text"}`}>
                            {navbarTranslations.regulation || "Regulasi"}
                        </div>
                    </a>
                    <div className="igahp-divider"></div>
                    <a href="/comingsoon">
                        <div className="igahp-l-medium black-text">
                            {navbarTranslations.contact || "Kontak"}
                        </div>
                    </a>
                    <div className="igahp-divider"></div>
                    <a href="/aplikasi">
                        <div className={`igahp-l-medium ${selectedPage == 6 ? "neutral-60-text" : "black-text"}`}>
                            {navbarTranslations.mobileApp || "Mobile Apps"}
                        </div>
                    </a>
                    <div className="igahp-divider"></div>
                    <div className="select-language-border">
                        <span
                            onClick={() => changeLanguage('id')}
                            className={language === 'id' ? 'selected-language' : ''}
                        >
                            Bahasa Indonesia
                        </span>
                        <span
                            onClick={() => changeLanguage('en')}
                            className={language === 'en' ? 'selected-language' : ''}
                        >
                            English
                        </span>
                    </div>
                    <div className="igahp-divider"></div>
                    <a href="/login" className={showLoginButton ? "igahp-s-semibold" : "igahp-s-semibold hide-btn-login"}>
                        <div className="btn-green-solid-pressed">
                            Login Lentera Hijau
                            <img src="/ic_arrow_right_white.svg" alt="" />
                        </div>
                    </a>
                    {/* <div className="igahp-divider"></div> */}
                </div>
            </div>
        </nav>
    );
};

export default NavBarAtas;
