import { useState } from 'react';
import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';
import axios from 'axios'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';
import FooterQuestion from './FooterQuestion';


const Product = () => {
  const { translations } = useLanguage(); // Mengambil terjemahan dari konteks bahasa
  const myProductTranslations = translations?.myproduct || {};
  const myLandingTranslations = translations?.mylanding || {};
  const tabsTranslations = myLandingTranslations.tabs || {};

  const [user, setUser] = useState({});


  const { loginContext } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const [dataSusun, setDataSusun] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);



  const apiUrl = process.env.REACT_APP_API_ENDPOINT;


  useEffect(() => {


    if (isLoading || isTokenExpired) {
      fetchDataTapak();
      fetchDataRusun();
    }


  }, []);

  const fetchDataTapak = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanTapak', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setData(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };


  const fetchDataRusun = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanSusun', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setDataSusun(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };

  const goToPerumahanDetail = (id) => {

    user['perumahan_id'] = id;
    loginContext(user); // isi context

  };

  const [activeSlide, setActiveSlide] = useState(0);


  const [active, setActive] = useState('tab-1');

  const handleClick = (event) => {
    setActive(event.target.id);
  };


  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas page="2" showButton={true} />

        <section className="body-home-content-product">


          <div className="section-headlines-header-text">

            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">
                {myProductTranslations.headline || "Bangunan Gedung Hijau"}</div>
              <div className="igahp-l-regular black-text"> {myProductTranslations.subheadline || "Bangunan hijau, atau sering juga disebut bangunan berkelanjutan, adalah bangunan yang dirancang dan dibangun dengan mempertimbangkan dampak lingkungan seminimal mungkin. Konsep ini mencakup seluruh siklus hidup bangunan, mulai dari perencanaan, pembangunan, pengoperasian, hingga pembongkaran."}</div>
            </div>
            {/* <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">
                {myProductTranslations.houseInformation ||
                  "Bangunan yang memperoleh sertifikasi ini harus memenuhi kriteria seperti efisiensi energi, konservasi air, pengembangan lokasi yang tepat guna, penggunaan material daur ulang dan pengelolaan sampah, kualitas udara dan kenyamanan yang baik, serta pengelolaan lingkungan gedung."}
              </div>
            </div> */}


          </div>

          <section className="section-body">


            <div className="body-card-panduan">
              {/* <div className="heading-text">
                <div className="igahp-highlight-semi-bold">Minat Bangunan Bersertifikat Hijau?</div>
              </div>
              <div className="sub-label">
                <div className="igahp-l-regular black-text">Bangunan bersertifikasi hijau sesuai dengan Skema IGAHP </div>
              </div> */}

              <div className="tab-content-product-certificate">
                <div className="group-tab-slider">
                  <div
                    className={`button-tab-slider-default ${active === 'tab-1' ? 'active' : ''}`}
                    id="tab-1"
                    onClick={handleClick}
                  >
                    {tabsTranslations.tab1 || 'Loading...'}
                  </div>
                  <div
                    className={`button-tab-slider-default ${active === 'tab-2' ? 'active' : ''}`}
                    id="tab-2"
                    onClick={handleClick}
                  >
                    {tabsTranslations.tab2 || 'Loading...'}
                  </div>
                  <div
                    className={`button-tab-slider-default ${active === 'tab-3' ? 'active' : ''}`}
                    id="tab-3"
                    onClick={handleClick}
                  >
                    {tabsTranslations.tab3 || 'Loading...'}
                  </div>
                </div>

                {/* Tab 1 */}
                <div className={`tab-page ${active === 'tab-1' ? 'active' : ''}`}>
                  {(!isLoading) && data ? (
                    <div className="group-card">
                      {data.map((item) => (
                        <div className="card-image-product" key={item.id}>
                          <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>
                            <div className="image-property">
                              <img
                                src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                alt="gambar properti"
                                className="image-child"
                              />
                            </div>
                            <div className="card-information">
                              <div className="property-detail-information">
                                <div className="property-tags">
                                  <div className="info-tags">
                                    <div className="unit-subsidi">{item.totalUnitSubsidiHijau} {myLandingTranslations.unitSubsidy}</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-title">
                                    <div className="perumahan-griya-asri">{item.nama_perumahan}</div>
                                  </div>
                                  <div className="house-address">
                                    <div className="cikande-banten">{item.kecamatan}</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-address">
                                    <div className="developer-name">{item.namaPengembang}</div>
                                  </div>
                                  <div className="wrapper-house-id">{item.idLokasiString}</div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>{myLandingTranslations.loading || 'Loading...'}</div>
                  )}
                </div>

                {/* Tab 2 */}
                <div className={`tab-page ${active === 'tab-2' ? 'active' : ''}`}>
                  {(!isLoading) && dataSusun ? (
                    <div className="group-card">
                      {dataSusun.map((item) => (
                        <div className="card-image-product" key={item.id}>
                          <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>
                            <div className="image-property">
                              <img
                                src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                alt="gambar properti"
                                className="image-child"
                              />
                            </div>
                            <div className="card-information">
                              <div className="property-detail-information">
                                <div className="property-tags">
                                  <div className="info-tags">
                                    <div className="unit-subsidi">{item.totalUnitSubsidiHijau} {myLandingTranslations.unitSubsidy}</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-title">
                                    <div className="perumahan-griya-asri">{item.nama_perumahan}</div>
                                  </div>
                                  <div className="house-address">
                                    <div className="cikande-banten">{item.kecamatan}</div>
                                  </div>
                                </div>
                                <div className="container-label-info">
                                  <div className="house-address">
                                    <div className="developer-name">{item.namaPengembang}</div>
                                  </div>
                                  <div className="wrapper-house-id">{item.idLokasiString}</div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>{myLandingTranslations.loading || 'Loading...'}</div>
                  )}
                </div>

                {/* Tab 3 */}
                <div className={`tab-page ${active === 'tab-3' ? 'active' : ''}`}>
                  <p>{tabsTranslations.noData || 'No Data'}</p>
                </div>
              </div>
            </div>


            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">
                      {myLandingTranslations.reportLabel || "LAPORKAN!!"}
                    </div>
                    <div className="support-text-attention">
                      <p>
                        {myLandingTranslations.reportDescription1 ||
                          "Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan"}
                      </p>
                      <p>
                        {myLandingTranslations.reportDescription2 ||
                          "Kementerian Perumahan dan Kawasan Permukiman"}
                      </p>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </section>


        </section>

        <FooterQuestion />
        <FooterFront />
      </div>

    </>



  )
}

export default Product
