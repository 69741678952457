import React, { useState } from "react";
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";
import { AuthContext } from '../../AuthContext';
import { useContext } from "react";
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';

const MenuPenerimaManfaat = () => {

    const { user, setUser } = useContext(AuthContext);

    return (
        <>


            <div class="hold-transition sidebar-mini layout-fixed">

                <div className="wrapper">

                    <Navbar />

                    <Sidebar />

                    <Outlet />

                    <Footer />

                   

                </div>



            </div>

        </>
    );
};

export default MenuPenerimaManfaat;
