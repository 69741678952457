import React from 'react'

import MonevStudioDashboard from './MonevStudioDashboard'

const MonevStudioContentDepan = () => {
  return (
    <>
      <MonevStudioDashboard />
    </>
  )
}

export default MonevStudioContentDepan
