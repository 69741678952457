import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';

const BankDataSlik = () => {

    const { user, setUser } = useContext(AuthContext);

    let refreshToken = null;


    const navigate = useNavigate()


    useEffect(() => {

        // const script2 = document.createElement('script');
        // script2.src = "/js/toast.js";
        // script2.async = true;
        // document.body.appendChild(script2);


        // Fetch options from API and update state

        // return () => { script2.remove(); }
    }, []);




    return (
        <>
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">LIST DATA <b>SLIK PENERIMA MANFAAT</b></h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                            {/* /.content-card-body */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    {/* <div className="card-header flex-column flex-md-row pb-3">
                                        <div className="head-label text-center">
                                            <div className="me-5 ms-n4 pe-5 mb-n6 mb-md-0"><div id="DataTables_Table_0_filter" className="dataTables_filter"><label><input type="search" className="form-control" placeholder="Search Product" aria-controls="DataTables_Table_0" /></label></div></div>



                                        </div>
                                        <div className="dt-action-buttons text-end pt-6 pt-md-0">
                                            <div className="dt-buttons btn-group flex-wrap">
                                                <div className="btn-group">
                                                    <a href="#"><button type="button" className="btn btn-success me-3">
                                                        Lolos Pengajuan SLIK</button></a>
                                                    <a href="#"><button type="button" className="btn btn-outline-danger">
                                                        Tidak Lolos SLIK</button></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="card-header ui-sortable-handle" style={{cursor: 'move'}}>

                                        <div className="card-tools">
                                            <a href="#"><button type="button" className="btn btn-success me-3">
                                                Lolos Pengajuan SLIK</button></a>
                                            <a href="#"><button type="button" className="btn btn-outline-danger">
                                                Tidak Lolos SLIK</button></a>
                                        </div>

                                    </div>
                                    <div className="card-body">
                                        <div id="example1_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer"><div className="row"><div className="col-sm-12 col-md-6"><div className="dt-buttons btn-group flex-wrap">
                                            <button className="btn btn-secondary buttons-copy buttons-html5" tabIndex={0} aria-controls="example1" type="button"><span>Copy</span></button> <button className="btn btn-secondary buttons-csv buttons-html5" tabIndex={0} aria-controls="example1" type="button"><span>CSV</span></button> <button className="btn btn-secondary buttons-excel buttons-html5" tabIndex={0} aria-controls="example1" type="button"><span>Excel</span></button> <button className="btn btn-secondary buttons-pdf buttons-html5" tabIndex={0} aria-controls="example1" type="button"><span>PDF</span></button> <button className="btn btn-secondary buttons-print" tabIndex={0} aria-controls="example1" type="button"><span>Print</span></button> <div className="btn-group"><button className="btn btn-secondary buttons-collection dropdown-toggle buttons-colvis" tabIndex={0} aria-controls="example1" type="button" aria-haspopup="true" aria-expanded="false"><span>Column visibility</span></button></div> </div></div><div className="col-sm-12 col-md-6"><div id="example1_filter" className="dataTables_filter"><label>Search:<input type="search" className="form-control form-control-sm" placeholder aria-controls="example1" /></label></div></div></div><div className="row"><div className="col-sm-12"><table id="example1" className="table table-bordered table-striped dataTable no-footer dtr-inline" role="grid" aria-describedby="example1_info">
                                            <thead>
                                                <tr role="row">
                                                    <th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="NO: activate to sort column ascending">NO</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="NIK KTP: activate to sort column ascending">NIK KTP</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="NAMA LENGKAP: activate to sort column ascending">NAMA</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="MINAT PROGRAM: activate to sort column ascending">MINAT PROGRAM</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="STATUS: activate to sort column ascending">STATUS</th>
                                                    <th className="sorting" tabIndex={0} aria-controls="example1" rowSpan={1} colSpan={1} aria-label="AKSI: activate to sort column ascending">AKSI</th>
                                                    </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="odd">
                                                    <td valign="top" colSpan={6} className="dataTables_empty">No data available in table</td>
                                                    </tr>
                                                    </tbody>
                                                    </table>
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-5">
                                                            <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                                            </div><div className="col-sm-12 col-md-7">
                                                                <div className="dataTables_paginate paging_simple_numbers" id="example1_paginate">
                                                                    <ul className="pagination">
                                                                        <li className="paginate_button page-item previous disabled" id="example1_previous">
                                                                            <a href="#" aria-controls="example1" data-dt-idx={0} tabIndex={0} className="page-link">Previous</a>
                                                                            </li>
                                                                            <li className="paginate_button page-item next disabled" id="example1_next">
                                                                                <a href="#" aria-controls="example1" data-dt-idx={1} tabIndex={0} className="page-link">Next</a>
                                                                                </li>
                                                                                </ul>
                                                                                </div>
                                                                                </div>
                                                                                </div>
                                                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default BankDataSlik
