import React from 'react'
import DashboardPenerimaManfaat from './DashboardPenerimaManfaat'

const ContentDepanPenerimaManfaat = () => {


return (
    <>
        
        <DashboardPenerimaManfaat />
    </>
  )
}

export default ContentDepanPenerimaManfaat
