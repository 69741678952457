import React, { useState, useEffect, useContext } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../AuthContext';

import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import axios from 'axios';


const ModalDaftarPenugasan = ({ onClose, editingData }) => {


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);




  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
  });

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const { user, setUser } = useContext(AuthContext);
  let refreshToken = null;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate()
  const { loginContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);

    if (isLoading || isTokenExpired) {
      console.log('Use effetc load fetchdata calling.....');
      // fetchDataRoles();
      fetchData();
    }
    return () => { script.remove(); }

  }, [isLoading, isTokenExpired]);


  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    try {
      const response = await axios.get(apiUrl + '/api/igahp/quesioner/listSurveyor', {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      console.log(response.data);
      setData(response.data);
      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };


  const handleClose = () => {
    onClose();
  };

  let sequenceNumber = 1;

  return (
    <>

      <div className="modal fade" id="ModalDaftarPenugasan" tabIndex={-1} role="dialog"
        aria-labelledby="ModalDaftarPenugasanLabel" aria-hidden="true" data-backdrop="static">


        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

              <div className="modal-body">
                <h4 className="modal-title mb-3">Daftar Penugasan</h4>
                <div className="container-fluid">
                  <div className="row">
                    
                  <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>NO</th>
                              <th>NIK</th>
                              <th>NAMA</th>
                              <th>EMAIL/USERNAME</th>
                              <th>NO. HANDPHONE</th>
                              <th>TOTAL PENUGASAN</th>
                              <th>AKSI</th>
                            </tr>
                          </thead>
                          <tbody>

                            {data.map((item) => (
                              <tr key={item.id}>
                                <td>{sequenceNumber++}</td>
                                <td>{item.nik}</td>
                                <td>{item.namaLengkap}</td>
                                <td>{item.email}</td>
                                <td>{item.handphone}</td>
                                <td>{item.totalPenugasan}</td>

                                <td style={{ textAlign: 'center' }}>
                                
                                </td>
                              </tr>
                            ))}


                          </tbody>
                        </table>

                    
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={handleClose}
                >
                  Tutup
                </button>
                {/* <button type="submit" className="btn btn-primary">
                  Simpan
                </button> */}
              </div>
            
          </div>
        </div>
      </div>
    </>
  );
};

// Reusable Input Field Component
const InputField = ({ label, value, setValue, type = 'text', required }) => (
  <div className="form-group">
    <label>{label}</label>
    <input
      type={type}
      className="form-control"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={`Masukkan ${label.toLowerCase()}`}
      required={required}
    />
  </div>
);

export default ModalDaftarPenugasan;
