import React from 'react'

import { AuthContext } from '../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';
// import ModalAsosiasi from './childs/ModalAsosiasi';
import ModalManajemenSurveyor from './childs/ModalManajemenSurveyor';
import Swal from 'sweetalert2';
import ModalDaftarPenugasan from './childs/ModalDaftarPenugasan';


const ManajemenSurveyor = () => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const { user, setUser } = useContext(AuthContext);
  let refreshToken = null;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate()
  const { loginContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDaftarPenugasanOpen, setModalDaftarPenugasanOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [editingData, setEditingData] = useState(null);

  // useEffect(() => {
  //   fetchData();  
  // }, []);



  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);

    if (isLoading || isTokenExpired) {
      console.log('Use effetc load fetchdata calling.....');
      // fetchDataRoles();
      fetchData();
    }
    return () => { script.remove(); }

  }, [isLoading, isTokenExpired]);


  const fetchDataRoles = () => {
    // Replace 'API_URL' with your actual API endpoint
    fetch(apiUrl + '/api/public/getAllRoles')
      .then(response => response.json())
      .then(data => setOptions(data))
      .catch(error => console.error('Error fetching data:', error));
  };


  const handleAddClick = () => {
    setEditingData(null);
    setModalOpen(true);
  }

  const handleEditClick = (data) => {
    console.log(data)
    setEditingData(data);
    setModalOpen(true);
  };

  const handleDaftarPenugasanClick = (data) => {
    console.log(data)
    setEditingData(data);
    setModalDaftarPenugasanOpen(true);
  };


  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    try {
      const response = await axios.get(apiUrl + '/api/igahp/quesioner/listSurveyor', {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      console.log(response.data);
      setData(response.data);
      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };


  const handleAddUsers = () => {
    // user['pemasaranrumah_state'] = 'add';
    // user['pemasaranrumah_id'] = null
    // loginContext(user);

    navigate(`/igahp/admin/ManajemenUsersTambah`);
  };



  const handleSelectRoleChange = event => {
    setSelectedRole(event.target.value);
    setSelectedRoleName(event.target.options[event.target.selectedIndex].text);
    // console.log('Selected role:', selectedRole);
    // console.log('Selected role name:', event.target.options[event.target.selectedIndex].text);
  };

  // Function to close the modal
  const handleModalClose = () => {
    console.log('Modal Close')
    setModalOpen(false);
    fetchData();

  };

  const handleModalDaftarPenugasanClose = () => {
    console.log('Modal Close')
    setModalDaftarPenugasanOpen(false);
    // fetchData();

  };


  const handleApproveClick = async (id) => {
    const confirmApprove = window.confirm("Are you sure you want to Approve this user?");


    if (confirmApprove) {


      let decodedToken = jwt_decode(user.accessToken);
      console.log("Decoded Token", decodedToken);
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log("Token expired.");
        try {
          const paramRefreshToken = {
            refreshToken: user.refreshToken,
            csrfToken: user.csrfToken,
          };
          refreshToken = await refreshTokenRequest(paramRefreshToken);
          // refreshToken = await refreshTokenRequest();
        } catch (error) {
          console.error('Expired. Please sign in.');
          navigate('/login');
        }
        if (refreshToken.statusCode === 403) {
          console.error('Expired. Please sign in.');
          navigate('/login');
        }
        console.log(refreshToken);
        user['accessToken'] = refreshToken.accessToken;
        setIsTokenExpired(true);
      } else {
        console.log("Valid token");
        // result = true;
      }

      doUpdateStatus(id, '1')


    } else {

    }
  }

  const doUpdateStatus = async (id, status) => {

    const requestBody = {
      user_id: id,
      status: status
    };


    try {
      await fetch(apiUrl + '/api/igahp/updateStatusUser', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),

      })
        .then(response => response.json())
        .then(data => {

          alert(data.message);
          fetchData();
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle error
          console.error(error);
          alert(data.status);
        });

    } catch (error) {
      console.error('An error occurred:', error);
    }

  }

  const handleDeniedClick = async (id) => {
    const confirmApprove = window.confirm("Are you sure you want to Non-Aktif this user?");


    if (confirmApprove) {


      let decodedToken = jwt_decode(user.accessToken);
      console.log("Decoded Token", decodedToken);
      let currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        console.log("Token expired.");
        try {
          const paramRefreshToken = {
            refreshToken: user.refreshToken,
            csrfToken: user.csrfToken,
          };
          refreshToken = await refreshTokenRequest(paramRefreshToken);
          // refreshToken = await refreshTokenRequest();
        } catch (error) {
          console.error('Expired. Please sign in.');
          navigate('/login');
        }
        if (refreshToken.statusCode === 403) {
          console.error('Expired. Please sign in.');
          navigate('/login');
        }
        console.log(refreshToken);
        user['accessToken'] = refreshToken.accessToken;
        setIsTokenExpired(true);
      } else {
        console.log("Valid token");
        // result = true;
      }

      doUpdateStatus(id, '0')


    } else {

    }
  }

  // const handleDeleteClick = async (itemToDelete) => {
  //   setModalOpen(false);

  //   const confirmDelete = window.confirm('Are you sure you want to delete this item?');


  //   if (confirmDelete) {

  //     const formData = new FormData();

  //     formData.append('id', itemToDelete.id);

  //     const sUrl = apiUrl + '/api/igahp/hapusSurveyor';

  //     // console.log(sUrl);
  //     try {
  //       await fetch(sUrl, {
  //         credentials: 'include', //getcookies
  //         method: 'POST',

  //         body: formData,
  //         headers: {

  //           Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
  //           'X-XSRF-TOKEN': user.csrfToken
  //         },
  //       })
  //         .then(response => response.json())
  //         .then(data => {

  //           fetchData();

  //           Toast.fire({
  //             icon: 'success',
  //             title: data.status
  //           });



  //           navigate("/depan/pemda/ManajemenSurveyor");

  //         })
  //         .catch(error => {

  //           Toast.fire({
  //             icon: 'error',
  //             title: error
  //           });

  //           // alert(error);
  //         });

  //     } catch (error) {
  //       console.error('An error occurred:', error);

  //     }

  //   }
  // };


  const handleDeleteClick = (item) => {
    if (window.confirm(`Apakah Anda yakin ingin menghapus surveyor dengan email ${item.email}?`)) {
      const headers = {
        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`, // Ganti 'yourAuthToken' dengan token otorisasi Anda
        'Content-Type': 'application/json',
      };

      Promise.all([
        fetch(apiUrl + `/api/cleanup/user?username=${item.nik}`, {
          method: 'DELETE',
          headers: headers,
        }),
        fetch(apiUrl + `/api/cleanup/surveyor?email=${item.email}`, {
          method: 'DELETE',
          headers: headers,
        }),
      ])
        .then(async ([userResponse, surveyorResponse]) => {
          const userResult = await userResponse.text();
          const surveyorResult = await surveyorResponse.text();

          if (userResponse.ok && surveyorResponse.ok) {
            fetchData();
            alert('Data berhasil dihapus.');
          } else {
            alert(`Terjadi kesalahan:\nUser: ${userResult}\nSurveyor: ${surveyorResult}`);
          }
        })
        .catch((error) => {
          alert('Terjadi kesalahan jaringan: ' + error.message);
        });
    }
  };

  const handleTotalPenugasanClick = (item) => {
    console.log('Total Penugasan clicked:', item);
    // Tambahkan logika untuk menampilkan detail data atau memuat modal
  };


  let sequenceNumber = 1;

  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Manajemen Enumerator</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Manajemen Enumerator</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">

            <div className="row">

              {isLoading ?
                <p>Loading Data...</p> :

                <>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <button
                          type="button"
                          id="tambahModal" data-toggle="modal"
                          data-target="#ModalManajemenSurveyor"
                          className="btn btn-success float-left"
                          onClick={handleAddClick}
                        >Tambah Data</button>

                      </div>
                      <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>NO</th>
                              <th>NIK</th>
                              <th>NAMA</th>
                              <th>EMAIL/USERNAME</th>
                              <th>NO. HANDPHONE</th>
                              <th>TOTAL PENUGASAN</th>
                              <th>AKSI</th>
                            </tr>
                          </thead>
                          <tbody>

                            {data.map((item) => (
                              <tr key={item.id}>
                                <td>{sequenceNumber++}</td>
                                <td>{item.nik}</td>
                                <td>{item.namaLengkap}</td>
                                <td>{item.email}</td>
                                <td>{item.handphone}</td>
                                <td style={{ textAlign: 'right' }}>
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      color: 'blue',
                                      textDecoration: 'underline',
                                    }}
                                    onClick={() => handleDaftarPenugasanClick(item)}
                                    data-toggle="modal"
                                    data-target="#ModalDaftarPenugasan"
                                  >
                                    {item.totalPenugasan}
                                  </span>
                                </td>

                                <td style={{ textAlign: 'center' }}>
                                  <button
                                    type="button"
                                    id="editHapusModal"
                                    data-toggle="modal"
                                    data-target="#ModalManajemenSurveyor"
                                    className="btn btn-primary"
                                    onClick={() => handleEditClick(item)}
                                  >
                                    Edit Data
                                  </button>

                                  <span style={{ margin: '0 10px' }}></span>

                                  {/* Uncomment jika ingin mengaktifkan tombol hapus */}
                                  {/* <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => handleDeleteClick(item)}
                                    >
                                      Hapus Data
                                    </button> */}
                                </td>
                              </tr>
                            ))}


                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>

              }


            </div>

            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}

        {isModalOpen && <ModalManajemenSurveyor onClose={handleModalClose} editingData={editingData} />}
        {isModalDaftarPenugasanOpen && <ModalDaftarPenugasan onClose={handleModalDaftarPenugasanClose} editingData={editingData} />}

      </div>
      {/* /.content-wrapper */}

      {/* <Footer /> */}
    </>
  )
}

export default ManajemenSurveyor
