import React from 'react'
import { useState } from 'react';
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import FooterQuestion from './FooterQuestion';
import { useLanguage } from '../../context/LanguageContext';
// import { useState } from 'react';


const IgahpDetail = () => {
  const { translations } = useLanguage(); // Mengambil terjemahan dari konteks bahasa
  const mySertifikasiTranslations = translations?.mysertifikasi|| {};
  const myLandingTranslations = translations?.mylanding || {};



  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas page="3" showButton={true} />

        <section className="body-home-content-sertifikasi">

          <div className="section-headlines-header-text">

            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">
                {mySertifikasiTranslations.sertifikasiTitle ||
                  "Sertifikasi Bangunan Hijau"}
              </div>
              <div className="igahp-l-regular black-text">
                {mySertifikasiTranslations.sertifikasiDescription ||
                  "Proses pemberian pengakuan formal kepada bangunan gedung yang telah memenuhi standar dan kriteria Bangunan Gedung Hijau (BGH) yang ditetapkan oleh pemerintah Indonesia."}
              </div>
            </div>

            {/* <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">Ciptakan hunian berwawasan lingkungan dengan Lentera HIjau Mobile</div>
              <div className="igahp-l-regular black-text"><p>1. Telah terintegrasi langsung dengan SIMBG</p></div>

            </div> */}


          </div>

          <section className="section-body-maxpage">
            
          <div className="body-card">
              <div className="content-sertifikasi-bgh">
                {/* <div className="header-text">
                  <div className="igahp-h2-semi-bold">
                    {myLandingTranslations.headerText || "Sertifikasi Bangunan Hijau"}
                  </div>
                </div> */}
                <div className="group-card">
                  <div className="card-product">
                    <img className="igahp-contribute" alt="" src="/ic_logo_bgh.png" />
                    <div className="supporting-text">
                      <div className="igahp-s-regular">
                        <p>
                          {myLandingTranslations.bghIntroBold ? (
                            // Jika ada terjemahan
                            <>
                              {myLandingTranslations.bghIntroStart}
                              <b>{myLandingTranslations.bghIntroBold}</b>
                              {myLandingTranslations.bghIntroEnd}
                            </>
                          ) : (
                            // Jika tidak ada terjemahan, gunakan fallback text
                            <>
                              Sertifikasi <b>Bangunan Gedung Hijau (BGH)</b> adalah sertifikasi yang diberikan kepada bangunan yang memenuhi kriteria lingkungan dan keberlanjutan tertentu. Sertifikasi ini bertujuan untuk mendorong pembangunan bangunan yang ramah lingkungan dan berkelanjutan di Indonesia.
                            </>
                          )}
                        </p>
                        <p>
                          {myLandingTranslations.bghCriteria ||
                            "Beberapa kriteria yang harus dipenuhi untuk mendapatkan sertifikasi BGH, antara lain: Efisiensi energi, Konservasi air, Pengelolaan limbah, Penggunaan material ramah lingkungan."}
                        </p>
                        <br />
                        <p>
                          {myLandingTranslations.bghValidity ||
                            "Sertifikat BGH berlaku selama 5 tahun dan diterbitkan secara elektronik melalui Sistem Informasi Manajemen Bangunan Gedung. Penerbitan sertifikat BGH tidak dipungut biaya."}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card-product">
                    <img className="igahp-contribute" alt="" src="/ic_edge.png" />
                    <div className="supporting-text">
                      <div className="igahp-s-regular">
                        <p>
                          {myLandingTranslations.edgeIntroBold ? (
                            <>
                              {myLandingTranslations.edgeIntroStart}
                              <b>{myLandingTranslations.edgeIntroBold}</b>
                              {myLandingTranslations.edgeIntroEnd}
                            </>
                          ) : (
                            <>
                              Sertifikasi <b>EDGE</b> adalah sistem sertifikasi bangunan hijau yang bertujuan untuk membuat bangunan lebih hemat sumber daya. EDGE merupakan inovasi dari International Finance Corporation (IFC), anggota Grup Bank Dunia.
                            </>
                          )}
                        </p>
                        <p>
                          {myLandingTranslations.edgeGoal ||
                            "EDGE merupakan singkatan dari Excellence in Design for Greater Efficiencies. Sertifikasi ini bertujuan untuk mengurangi dampak lingkungan dari bangunan dalam tiga area, yaitu: Konsumsi energi langsung, Konsumsi air, Jejak energi dari bahan konstruksi."}
                        </p>
                        <br />
                        <p>
                          {myLandingTranslations.edgeBenefit ||
                            "Proyek yang mendapatkan sertifikasi EDGE dapat menghemat minimal 20% di tiga kategori sumber daya."}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card-product">
                    <img className="igahp-contribute" alt="" src="/ic_greenship.png" />
                    <div className="supporting-text">
                      <div className="igahp-s-regular">
                        <p>
                          {myLandingTranslations.greenshipIntroBold ? (
                            <>
                              {myLandingTranslations.greenshipIntroStart}
                              <b>{myLandingTranslations.greenshipIntroBold}</b>
                              {myLandingTranslations.greenshipIntroEnd}
                            </>
                          ) : (
                            <>
                              Sertifikasi <b>Greenship</b> adalah sistem penilaian bangunan hijau di Indonesia yang dikelola oleh Green Building Council Indonesia (GBCI). Sertifikasi ini bertujuan untuk mendorong pembangunan properti yang berkelanjutan dan ramah lingkungan.
                            </>
                          )}
                        </p>
                        <p>
                          {myLandingTranslations.greenshipGoal ||
                            "Sertifikasi ini bertujuan untuk mendorong pembangunan berkelanjutan dan ramah lingkungan pada sektor properti di Indonesia."}
                        </p>
                        <br />
                        <p>
                          {myLandingTranslations.greenshipBenefit ||
                            "Sertifikasi Greenship dapat digunakan sebagai bukti bahwa sebuah perusahaan memiliki bangunan yang hemat energi, hemat air, hemat gas rumah kaca, serta konservasi jalan."}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">
                      {myLandingTranslations.reportLabel || "LAPORKAN!!"}
                    </div>
                    <div className="support-text-attention">
                      <p>
                        {myLandingTranslations.reportDescription1 ||
                          "Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan"}
                      </p>
                      <p>
                        {myLandingTranslations.reportDescription2 ||
                          "Kementerian Perumahan dan Kawasan Permukiman"}
                      </p>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </section>

        </section>

        <FooterQuestion />
        <FooterFront />
      </div>

    </>



  )
};

export default IgahpDetail
