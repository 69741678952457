import React, { useState } from "react";
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";

// import Badge from "../additional/Bagde";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import Navbar from "./Navbar";
import SidebarPengembang from "./SidebarPengembang";


// import Navbar from "./Navbar";
// import $ from "jquery";
import { useEffect } from "react";
// import ContentAsosiasi from "../master/ContentAsosiasi";
import Footer from "../Footer";

const MenuPengembangAdmin = (props) => {
    const { user, setUser } = useContext(AuthContext);

    useEffect(() => {
        // const script = document.createElement("script");
        // script.src = "/js/highlight.js";
        // script.async = true;
        // document.body.appendChild(script);

        // console.log('masuk ke menu pengembang admin');
        return () => {
            // script.remove();
        };
    }, []);

    return (
        <>


            {/* <div className="hold-transition sidebar-mini layout-fixed"> */}
            <div className="hold-transition sidebar-mini layout-footer-fixed">

                <div className="wrapper">

                    <Navbar />

                    <SidebarPengembang />

                    <Outlet />

                    <Footer />

                   

                </div>



            </div>

        </>
    );
};

export default MenuPengembangAdmin;
