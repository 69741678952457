import React from 'react'

import { AuthContext } from '../../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';
// import ModalAsosiasi from './childs/ModalAsosiasi';
import Swal from 'sweetalert2';
import LocationSelect2 from '../../shared/LocationSelect2';


const RptPerformanceSurveyorKecamatan = () => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const { user, setUser } = useContext(AuthContext);
  let refreshToken = null;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate()
  const { loginContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [selectedProvince2, setSelectedProvince2] = useState('');
  const [selectedCity2, setSelectedCity2] = useState('');
  const [selectedDistrict2, setSelectedDistrict2] = useState('');
  const [selectedVillage2, setSelectedVillage2] = useState('');
  const [wilayahPilih2, setWilayahPilih2] = useState('');



  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);
    if (isLoading || isTokenExpired) {
      fetchData(wilayahPilih2);
    }
    return () => { script.remove(); }
  }, [isLoading, isTokenExpired]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);
    if (selectedCity2) {
      setWilayahPilih2(selectedCity2)
      setIsLoading(true);
    }
    return () => { script.remove(); }
  }, [selectedCity2]);


  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);
    if (selectedDistrict2) {
      setWilayahPilih2(selectedDistrict2)
      setIsLoading(true);
    }
    return () => { script.remove(); }
  }, [selectedDistrict2]);


  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);
    if (selectedVillage2) {
      setWilayahPilih2(selectedVillage2)
      setIsLoading(true);
    }
    return () => { script.remove(); }
  }, [selectedVillage2]);

  const fetchData = async (kodeWilayah) => {

    if (!kodeWilayah) {
      kodeWilayah = '0';
    }

    try {
      const response = await axios.get(apiUrl + '/api/igahp/dashboard/rptSurveyorPemda/' + kodeWilayah, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      // console.log(response.data);
      setData(response.data);
      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };


  let sequenceNumber = 1;

  let totals = {
    pemilikan: 0,
    pembangunan: 0,
    perbaikan: 0,
    sewa: 0,
    minatRusun: 0,
    approval: 0,
    revisi: 0,
    approved: 0,
    total: 0,
    tidakMinat: 0
  }
  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Monitoring Enumerator per Kecamatan</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Monitoring Enumerator per Kecamatan</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* Main content */}

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="form-group">
                <h8>Pilih Wilayah</h8>
                <div className="row">
                  <LocationSelect2
                    onProvinceChange={setSelectedProvince2}
                    onCityChange={setSelectedCity2}
                    onDistrictChange={setSelectedDistrict2}
                    onVillageChange={setSelectedVillage2}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              {isLoading ? (
                <p>Loading Data...</p>
              ) : (
                <>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>NO</th>
                              <th>NAMA KECAMATAN</th>
                              <th>NAMA DESA/KELURAHAN</th>
                              <th className="text-end">TIDAK MINAT</th>
                              <th className="text-end">MINAT KEPEMILIKAN</th>
                              <th className="text-end">MINAT PEMBANGUNAN</th>
                              <th className="text-end">MINAT PERBAIKAN</th>
                              <th className="text-end">MINAT SEWA</th>
                              <th className="text-end">MINAT RUSUN</th>
                              <th className="text-end">MENUNGGU APPROVAL</th>
                              <th className="text-end">REVISI</th>
                              <th className="text-end">APPROVED</th>
                              <th className="text-end">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              // Accumulate totals for each column
                              totals.pemilikan += item.pemilikanRumah || 0;
                              totals.pembangunan += item.pembangunanRumah || 0;
                              totals.perbaikan += item.perbaikan || 0;
                              totals.sewa += item.sewa || 0;
                              totals.minatRusun += item.minatRusun || 0;
                              totals.approval += item.approval || 0;
                              totals.revisi += item.revisi || 0;
                              totals.approved += item.approved || 0;
                              totals.total += item.total || 0;
                              totals.tidakMinat += item.tidakMinat || 0;

                              return (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.kecamatan}</td>
                                  <td>{item.kelDes}</td>
                                  <td className="text-end">{item.tidakMinat ? item.tidakMinat.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.pemilikanRumah ? item.pemilikanRumah.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.pembangunanRumah ? item.pembangunanRumah.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.perbaikan ? item.perbaikan.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.sewa ? item.sewa.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.minatRusun ? item.minatRusun.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.approval ? item.approval.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.revisi ? item.revisi.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.approved ? item.approved.toLocaleString() : '0'}</td>
                                  <td className="text-end">{item.total ? item.total.toLocaleString() : '0'}</td>
                                </tr>
                              );
                            })}
                          </tbody>

                          {/* Add totals row */}
                          <tfoot>
                            <tr>
                              <td colSpan="3">Total</td>
                              <td className="text-end">{totals.tidakMinat.toLocaleString()}</td>
                              <td className="text-end">{totals.pemilikan.toLocaleString()}</td>
                              <td className="text-end">{totals.pembangunan.toLocaleString()}</td>
                              <td className="text-end">{totals.perbaikan.toLocaleString()}</td>
                              <td className="text-end">{totals.sewa.toLocaleString()}</td>
                              <td className="text-end">{totals.minatRusun.toLocaleString()}</td>
                              <td className="text-end">{totals.approval.toLocaleString()}</td>
                              <td className="text-end">{totals.revisi.toLocaleString()}</td>
                              <td className="text-end">{totals.approved.toLocaleString()}</td>
                              <td className="text-end">{totals.total.toLocaleString()}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>


        {/* /.content */}


      </div>
      {/* /.content-wrapper */}

      {/* <Footer /> */}
    </>
  )
}

export default RptPerformanceSurveyorKecamatan
