import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { AuthContext } from "./AuthContext";
import MenuAdmin from './components/igahp_admin/MenuAdmin';
import ContentListAkun from './components/igahp_admin/ContentListAkun'
import TambahLokasi from './components/igahp_admin/LokasiPerumahan/TambahLokasi';

import ContentAsosiasi from './components/master/ContentAsosiasi'
import ContentPengembang from './components/master/ContentPengembang';
import ListLokasi from './components/igahp_admin/LokasiPerumahan/ListLokasi';
import PengembangDashboard from './components/master/PengembangDashboard';
import DigitalSiteplan from './components/igahp_admin/LokasiPerumahan/DigitalSiteplan';
import InfoLokasi from './components/igahp_admin/LokasiPerumahan/InfoLokasi';
import LokasiProfile from './components/igahp_admin/LokasiPerumahan/LokasiProfile';
import About from './components/igahp_admin/LokasiPerumahan/About';
import MenuPengembangAdmin from './components/igahp_pengembang_admin/MenuPengembangAdmin';

import PengembangAdminDashboard from './components/igahp_pengembang_admin/PengembangAdminDashboard';
import PengembangAdminListLokasi from './components/igahp_pengembang_admin/PengembangAdminListLokasi';
import PengembangKantorPemasaran from './components/igahp_pengembang_admin/PengembangKantorPemasaran';
import PengembangSurvey from './components/igahp_pengembang_admin/PengembangSurvey';
import PengembangLokasiProfile from './components/igahp_pengembang_admin/PengembangLokasiProfile';
import PengembangLokasiTambah from './components/igahp_pengembang_admin/PengembangLokasiTambah';
import PengembangLokasiSitePlan from './components/igahp_pengembang_admin/PengembangLokasiSitePlan';
import PengembangLokasiTipeRumah from './components/igahp_pengembang_admin/PengembangLokasiTipeRumah';
import PengembangLokasiTipeRumahTambah from './components/igahp_pengembang_admin/childs/PengembangLokasiTipeRumahTambah';
import PengembangLokasiBlok from './components/igahp_pengembang_admin/PengembangLokasiBlok';
import PengembangLokasiBlokTambah from './components/igahp_pengembang_admin/childs/PengembangLokasiBlokTambah';
import PengembangKantorPemasaranTambah from './components/igahp_pengembang_admin/childs/PengembangKantorPemasaranTambah';
import PengembangInfo from './components/igahp_pengembang_admin/PengembangInfo';
import PengembangInfoTambah from './components/igahp_pengembang_admin/childs/PengembangInfoTambah';
import ManajemenUsers from './components/igahp_admin/ManajemenUsers';
import ManajemenUsersTambah from './components/igahp_admin/childs/ManajemenUsersTambah';
import PengembangContentDepan from './components/igahp_pengembang_admin/PengembangContentDepan';
import ManajemenLokasi from './components/igahp_admin/ManajemenLokasi';
import ManajemenLokasiApproval from './components/igahp_admin/ManajemenLokasiApproval';
import ManajemenPenugasan from './components/igahp_admin/ManajemenPenugasan';
import ManajemenPenugasanTambah from './components/igahp_admin/childs/ManajemenPenugasanTambah';
import MenuTaBgh from './components/igahp_tabgh/MenuTaBgh';
import TaBghContentDepan from './components/igahp_tabgh/TaBghContentDepan';
import TaBghHasilPenilaian from './components/igahp_tabgh/TaBghHasilPenilaian';
import MenuBank from './components/igahp_bank/MenuBank'
import BankContentDepan from './components/igahp_bank/BankContentDepan'
import BankDataCompare from './components/igahp_bank/BankDataCompare'
import BankDataSlik from './components/igahp_bank/BankDataSlik'
import MenuSekBgh from './components/igahp_sekbgh/MenuSekBgh';
import SekBghContentDepan from './components/igahp_sekbgh/SekBghContentDepan';
import PengembangPengajuanBgh from './components/igahp_pengembang_admin/childs/PengembangPengajuanBgh';
import PengembangPengajuanBghTambah from './components/igahp_pengembang_admin/childs/PengembangPengajuanBghTambah';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import MenuPemda from './components/igahp_pemda/MenuPemda';
import PemdaContentDepan from './components/igahp_pemda/PemdaContentDepan';
import ListPengajuanBgh from './components/igahp_pemda/ListPengajuanBgh';
import PengajuanBghDetail from './components/igahp_pemda/PengajuanBghDetail';
import MenuPemohon from './components/igahp_pemohon/MenuPemohon';
import PemohonContentDepan from './components/igahp_pemohon/PemohonContentDepan';
import ListPermohonan from './components/igahp_pemohon/ListPermohonan';
import PemdaDashboard from './components/igahp_pemda/PemdaDashboard';
import AdminDashboard from './components/igahp_admin/AdminDashboard';
import EmailVerification from './components/Front/EmailVerification';
import ComingSoon from './components/Front/ComingSoon';
import PengembangPenugasanTambah from './components/igahp_pengembang_admin/childs/PengembangPenugasanTambah';
import PengembangPenugasanBgh from './components/igahp_pengembang_admin/childs/PengembangPenugasanBgh';
import PengembangProfileNew from './components/igahp_pengembang_admin/PengembangProfileNew';
import PengembangProfile from './components/igahp_pengembang_admin/PengembangProfileFirstPage';
import BankProfile from './components/igahp_bank/BankProfile';
import BankDashboard from './components/igahp_bank/BankDashboard';
import ToastTes from './components/igahp_pemda/ToastTes';
import ManajemenBanks from './components/igahp_admin/ManajemenBanks';
import ManajemenAsosiasi from './components/igahp_admin/ManajemenAsosiasi';
import PengembangBank from './components/igahp_pengembang_admin/PengembangBank';
import PengembangLokasiFasilitas from './components/igahp_pengembang_admin/PengembangLokasiFasilitas';
import PengembangDataMinat from './components/igahp_pengembang_admin/PengembangDataMinat';
import PengembangAdminListLokasi2 from './components/igahp_pengembang_admin/PengembangAdminListLokasi2';
import PengembangLokasiProfile2 from './components/igahp_pengembang_admin/PengembangLokasiProfile2';
import MinatInfo from './components/igahp_pengembang_admin/peminatan/MinatInfo';
import MenuPengembangAdminPerorangan from './components/igahp_pengembang_perorangan/MenuPengembangAdminPerorangan';
import PengembangContentDepanPerorangan from './components/igahp_pengembang_perorangan/PengembangContentDepanPerorangan';
import PenghuniProfile from './components/igahp_pengembang_perorangan/PenghuniProfile';

import PengembangLokasiTambahPerorangan from './components/igahp_pengembang_perorangan/PengembangLokasiTambahPerorangan';
import PengembangListLokasiPerorangan from './components/igahp_pengembang_perorangan/PengembangListLokasiPerorangan';
import MenuRumahProfilePerorangan from './components/igahp_pengembang_perorangan/MenuRumahProfilePerorangan';
import PengajuanBghPerorangan from './components/igahp_pengembang_perorangan/PengajuanBghPerorangan';
import PengajuanBghPeroranganTambah from './components/igahp_pengembang_perorangan/PengajuanBghPeroranganTambah';
import PenugasanBghPerorangan from './components/igahp_pengembang_perorangan/PenugasanBghPerorangan';
import PenugasanBghPeroranganTambah from './components/igahp_pengembang_perorangan/PenugasanBghPeroranganTambah';
import ProfileDokumen from './components/igahp_pengembang_perorangan/profiledokumen/ProfileDokumen';
import PengembangAdminDashboardPerorangan from './components/igahp_pengembang_perorangan/PengembangAdminDashboardPerorangan';
import AkunSetting from './components/AkunSetting';
import ListPendataanBgh from './components/igahp_pemda/pendataan/ListPendataanBgh';
import LolosSubsidiChecking from './components/igahp_pemda/pendataan/LolosSubsidiChecking';
import IgahpDataQuesionerTable from './components/igahp_pemda/pendataan/IgahpDataQuesionerTable';
import IgahpDataQuesionerTable2 from './components/igahp_pemda/pendataan/IgahpDataQuesionerTable2';
import AdminPenugasanPendataan from './components/igahp_admin/pendataan/AdminPenugasanPendataan';
import IgahpDataSiapSurvei from './components/igahp_pemda/pendataan/IgahpDataSiapSurvei';
import IgahpBelumSubsidiChecking from './components/igahp_pemda/pendataan/IgahpBelumSubsidiChecking';
import MenuSurveyor from './components/igahp_surveyor/MenuSurveyor';
import SurveyorDataSiapSurvei from './components/igahp_surveyor/pendataan/SurveyorDataSiapSurvei'
import SubsidiCheckingResume from './components/igahp_pemda/pendataan/SubsidiCheckingResume'
import TidakLolosSubsidiChecking from './components/igahp_pemda/pendataan/TidakLolosSubsidiChecking'
import QuesionerDataAwal from './components/igahp_pemda/pendataan/QuesionerDataAwal';
import IgahpDataHasilSurvei from './components/igahp_pemda/pendataan/IgahpDataHasilSurvei';
import IgahpDataAnalisaHasilSurvei from './components/igahp_pemda/pendataan/IgahpDataAnalisaHasilSurvei';
import SurveyorHasilSurvei from './components/igahp_surveyor/pendataan/SurveyorHasilSurvei'
import AdminApprovalPendataan from './components/igahp_admin/pendataan/AdminApprovalPendataan';
import PemdaApprovalPendataan from './components/igahp_pemda/pendataan/PemdaApprovalPendataan';
import PemdaPenugasanPendataan from './components/igahp_pemda/pendataan/PemdaPenugasanPendataan';
import SurveyorAnalisaSurvei from './components/igahp_surveyor/pendataan/SurveyorAnalisaSurvei'
import SurveyorContentDepan from './components/igahp_surveyor/SurveyorContentDepan';
import SurveyorDashboard from './components/igahp_surveyor/SurveyorDashboard';
import MenuAdminSurveyor from './components/igahp_admin_surveyor/MenuAdminSurveyor';
import AdminSurveyorContentDepan from './components/igahp_admin_surveyor/AdminSurveyorContentDepan';
import AdminSurveyorDashboard from './components/igahp_admin_surveyor/AdminSurveyorDashboard';
import MenuPimpinan from './components/igahp_pimpinan/MenuPimpinan';
import PimpinanContentDepan from './components/igahp_pimpinan/PimpinanContentDepan';
import PimpinanDashboard from './components/igahp_pimpinan/PimpinanDashboard';
import NasionalPerkotaan from './components/igahp_general/NasionalPerkotaan';
import MenuSatker from './components/igahp_satker/MenuSatker';
import SatkerContentDepan from './components/igahp_satker/SatkerContentDepan';
import SatkerDashboard from './components/igahp_satker/SatkerDashboard';
import RptPerformanceSurveyor1 from './components/igahp_pemda/pendataan/RptPerformanceSurveyor1';
import RptPerformance1 from './components/igahp_surveyor/pendataan/RptPerformance1';
import ManajemenSurveyor from './components/igahp_pemda/ManajemenSurveyor';
import UploadRumahquCsv from './components/igahp_admin/UploadRumahquCsv';
import MenuPenerimaManfaat from './components/igahp_penerima_manfaat/MenuPenerimaManfaat';
import ContentDepanPenerimaManfaat from './components/igahp_penerima_manfaat/ContentDepanPenerimaManfaat';
import DashboardPenerimaManfaat from './components/igahp_penerima_manfaat/DashboardPenerimaManfaat';
import ProfileDokumenPenerimaManfaat from './components/igahp_penerima_manfaat/profiledokumen/ProfileDokumenPenerimaManfaat';
import RptPerformanceSurveyorKelurahan from './components/igahp_pemda/pendataan/RptPerformanceSurveyorKelurahan';
import RptPerformanceSurveyorKecamatan from './components/igahp_pemda/pendataan/RptPerformanceSurveyorKecamatan';
import RptPerformanceSurveyorWilayah from './components/igahp_pemda/pendataan/RptPerformanceSurveyorWilayah';
import MenuMonevStudio from './components/igahp_monevstudio/MenuMonevStudio';
import MonevStudioContentDepan from './components/igahp_monevstudio/MonevStudioContentDepan';
import MonevStudioDashboard from './components/igahp_monevstudio/MonevStudioDashboard';
import RptAlamat from './components/igahp_surveyor/pendataan/RptAlamat';
import RptAlamatPemda from './components/igahp_pemda/RptAlamatPemda';
import GeoTagTest from './components/shared/GeoTagTest';

// import DataTableWithEdit from './components/igahp_pemda/pendataan/DataTableWithEdit';

const MainMenu = (props) => {
    const { user, setUser } = useContext(AuthContext);

    const $ = window.$;
    function loadTreeview() {
        $('[data-widget="treeview"]').each(function () {
            if ($(this).data('treeview-init') === undefined) {
                $(this).Treeview('init');
                $(this).data('treeview-init', true);
            }
        });
    }


    useEffect(() => {
        loadTreeview();

    }, [])

    //handle back page harus balik ke login lagi
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function (event) {
    //     window.history.go(1);
    // };


    // require("./css/reset.css");
    // require("./css/style-lte.css");
    require("./css/vendor/bootstrap/css/bootstrap-reboot.min.css");
    require("./css/vendor/bootstrap/css/bootstrap.min.css");
    require("./css/dist/css/adminlte.min.css")



    // require("./css/vendor/animate/animate.min.css");
    // require("./css/vendor/bootstrap-icons/bootstrap-icons.min.css");
    // require("./css/vendor/boxicons/css/boxicons.min.css");
    // require("./css/vendor/glightbox/css/glightbox.min.css");
    // require("./css/vendor/swiper/swiper-bundle.min.css");
    // require("./css/plugins/fontawesome-free/css/all.min.css");
    // require("./css/plugins/jqvmap/jqvmap.min.css");
    // require("./css/plugins/overlayScrollbars/css/OverlayScrollbars.min.css");
    // require("./css/plugins/summernote/summernote-bs4.min.css");
    // require("./css/plugins/sweetalert2-theme-bootstrap-4/bootstrap-4.min.css");
    // require("./css/plugins/toastr/toastr.min.css");
    // require("./css/plugins/fontawesome-free/css/all.min.css")
    // require("./css/plugins/daterangepicker/daterangepicker.css")
    // require("./css/plugins/icheck-bootstrap/icheck-bootstrap.min.css")
    // require("./css/plugins/bootstrap-colorpicker/css/bootstrap-colorpicker.min.css")
    // require("./css/plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css")
    // require("./css/plugins/select2/css/select2.min.css")
    // require("./css/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css")
    // require("./css/plugins/bootstrap4-duallistbox/bootstrap-duallistbox.min.css")
    // require("./css/plugins/bs-stepper/css/bs-stepper.min.css")
    // require("./css/plugins/dropzone/min/dropzone.min.css")


    //console.log(state);

    // console.log('------- main menu ----------')
    // console.log(user.roles)
    

    if (user.roles.includes("ROLE_IGAHP_ADMIN")) {        
        return (
            <>
                <Routes>
                    <Route element={<MenuAdmin />}>
                        <Route index element={<AdminDashboard />} />

                        <Route path="admin/dashboard" element={<AdminDashboard />} />
                        <Route path="admin/ManajemenUsers" element={<ManajemenUsers />} />
                        <Route path="admin/ManajemenLokasi" element={<ManajemenLokasi />} />
                        <Route path="admin/ManajemenPenugasan" element={<ManajemenPenugasan />} />
                        <Route path="admin/ManajemenPenugasanTambah" element={<ManajemenPenugasanTambah />} />
                        <Route path="admin/ManajemenLokasiApproval" element={<ManajemenLokasiApproval />} />
                        <Route path="admin/ManajemenBanks" element={<ManajemenBanks />} />
                        <Route path="admin/ManajemenAsosiasi" element={<ManajemenAsosiasi />} />
                        <Route path="pengembang/dataMinat" element={<PengembangDataMinat />} />
                        <Route path="pengembang/MinatInfo" element={<MinatInfo />} />
                        {/* <Route path="admin/dataMinat" element={<AdminDataMinat />} /> */}

                        <Route path="admin/ManajemenUsersTambah" element={<ManajemenUsersTambah />} />
                        <Route path="admin/AdminPenugasanPendataan" element={<AdminPenugasanPendataan />} />
                        <Route path="admin/AdminApprovalPendataan" element={<AdminApprovalPendataan />} />
                        <Route path="admin/UploadRumahquCsv" element={<UploadRumahquCsv />} />
                
                        <Route path="PengembangDashboard" element={<PengembangDashboard />} />
                        <Route path="ContentAsosiasi" element={<ContentAsosiasi />} />
                        <Route path="ContentPengembang" element={<ContentPengembang />} />
                        <Route path="ListLokasi" element={<ListLokasi />} />
                        <Route path="TambahLokasi/:id" element={<TambahLokasi />} />
                        <Route path="InfoLokasi/:id" element={<InfoLokasi />} />
                        <Route path="DigitalSiteplan" element={<DigitalSiteplan />} />
                        <Route path="LokasiProfile" element={<LokasiProfile />} />
                        <Route path="about" element={<About />} />



                    </Route>
                </Routes>
            </>
        )
    } else if (user.roles.includes("ROLE_IGAHP_ADMIN_SURVEYOR")) {

        return (
            <>
                <Routes>
                    <Route element={<MenuAdminSurveyor />} >
                        {/* <Route index element={<PengembangAdminDashboard />} /> */}
                        <Route index element={<AdminSurveyorContentDepan />} />
                        <Route path="adminsurveyor/penugasanpendataan" element={<AdminPenugasanPendataan />} />
                        <Route path="adminsurveyor/approvalpendataan" element={<AdminApprovalPendataan />} />
                        <Route path="adminsurveyor/adminSurveyorDashboard" element={<AdminSurveyorDashboard />} />
                    </Route>
                </Routes>
            </>
        )
    } else if (user.roles.includes("ROLE_IGAHP_SATKER")) {

        return (
            <>
                <Routes>
                    <Route element={<MenuSatker />} >
                        {/* <Route index element={<PengembangAdminDashboard />} /> */}
                        <Route index element={<SatkerContentDepan />} />
                        <Route path="adminsurveyor/penugasanpendataan" element={<AdminPenugasanPendataan />} />
                        <Route path="adminsurveyor/approvalpendataan" element={<AdminApprovalPendataan />} />
                        <Route path="satker/dashboard" element={<SatkerDashboard />} />
                    </Route>
                </Routes>
            </>
        )

    } else if (user.roles.includes("ROLE_IGAHP_PENGEMBANG_ADMIN")) {

        return (
            <>
                <Routes>
                    <Route element={<MenuPengembangAdmin />} >
                        {/* <Route index element={<PengembangAdminDashboard />} /> */}
                        <Route index element={<PengembangContentDepan />} />
                        <Route path="pengembang/PengembangAdminListLokasi" element={<PengembangAdminListLokasi />} />
                        <Route path="pengembang/PengembangAdminListLokasi2" element={<PengembangAdminListLokasi2 />} />
                        <Route path="pengembang/MinatInfo" element={<MinatInfo />} />
                        <Route path="pengembang/PengembangLokasiTambah" element={<PengembangLokasiTambah />} /> 
                        <Route path="pengembang/PengembangProfile" element={<PengembangProfileNew />} />
                        <Route path="pengembang/LokasiFasilitas" element={<PengembangLokasiFasilitas />} />
                        <Route path="pengembang/dataMinat" element={<PengembangDataMinat />} />
                        <Route path="pengembang/dashboard" element={<PengembangAdminDashboard />} />
                        <Route path="pengembang/PengembangPenugasanTambah" element={<PengembangPenugasanTambah />} />
                        <Route path="pengembang/PengembangPenugasanBgh" element={<PengembangPenugasanBgh />} />
                        <Route path="pengembang/PengembangBank" element={<PengembangBank />} />
                        <Route path="pengembang/PengembangLokasiProfile" element={<PengembangLokasiProfile />} />
                        <Route path="pengembang/PengembangLokasiProfile2" element={<PengembangLokasiProfile2 />} />                        
                        <Route path="pengembang/PengembangKantorPemasaran" element={<PengembangKantorPemasaran />} />
                        <Route path="pengembang/PengembangKantorPemasaranTambah" element={<PengembangKantorPemasaranTambah />} />
                        <Route path="pengembang/PengembangLokasiSitePlan" element={<PengembangLokasiSitePlan />} />
                        <Route path="pengembang/PengembangLokasiTipeRumah" element={<PengembangLokasiTipeRumah />} />
                        <Route path="pengembang/PengembangLokasiTipeRumahTambah" element={<PengembangLokasiTipeRumahTambah />} />
                        <Route path="pengembang/PengembangLokasiBlok" element={<PengembangLokasiBlok />} />
                        <Route path="pengembang/PengembangLokasiBlokTambah" element={<PengembangLokasiBlokTambah />} />
                        <Route path="pengembang/PengembangSurvey" element={<PengembangSurvey />} />
                        <Route path="pengembang/PengembangPengajuanBgh" element={<PengembangPengajuanBgh />} />
                        <Route path="pengembang/PengembangPengajuanBghTambah" element={<PengembangPengajuanBghTambah />} />
                        <Route path="pengembang/Akun" element={<AkunSetting />} />

                    </Route>
                </Routes>
            </>
        )

    } else if (user.roles.includes("ROLE_IGAHP_PENERIMA_MANFAAT")) {
        return (
            <>
                <Routes>
                    <Route element={<MenuPenerimaManfaat />} >
                        
                        <Route index element={<ContentDepanPenerimaManfaat />} />

                        <Route path="perorangan/dashboard" element={<DashboardPenerimaManfaat />} />

                        <Route path="perorangan/PenghuniProfile" element={<PenghuniProfile />} />

                        <Route path="perorangan/lokasiperorangan" element={<PengembangLokasiTambahPerorangan />} />
                        
                        <Route path="perorangan/listlokasiperorangan" element={<PengembangListLokasiPerorangan />} />

                        <Route path="perorangan/rumahinfo" element={<MenuRumahProfilePerorangan />} />

                        <Route path="perorangan/pengajuanbgh" element={<PengajuanBghPerorangan />} />

                        <Route path="perorangan/pengajuanbghtambah" element={<PengajuanBghPeroranganTambah />} />

                        <Route path="perorangan/penugasanbgh" element={<PenugasanBghPerorangan />} />

                        <Route path="perorangan/penugasanbghtambah" element={<PenugasanBghPeroranganTambah />} />

                        <Route path="penerima/ProfileDokumenPenerimaManfaat" element={<ProfileDokumenPenerimaManfaat />} />


           
                    </Route>
                </Routes>
            </>
        )

    } else if (user.roles.includes("ROLE_IGAHP_PERORANGAN")) {
        return (
            <>
                <Routes>
                    <Route element={<MenuPengembangAdminPerorangan />} >
                        
                        <Route index element={<PengembangContentDepanPerorangan />} />

                        <Route path="perorangan/dashboard" element={<PengembangAdminDashboardPerorangan />} />

                        <Route path="perorangan/PenghuniProfile" element={<PenghuniProfile />} />

                        <Route path="perorangan/lokasiperorangan" element={<PengembangLokasiTambahPerorangan />} />
                        
                        <Route path="perorangan/listlokasiperorangan" element={<PengembangListLokasiPerorangan />} />

                        <Route path="perorangan/rumahinfo" element={<MenuRumahProfilePerorangan />} />

                        <Route path="perorangan/pengajuanbgh" element={<PengajuanBghPerorangan />} />

                        <Route path="perorangan/pengajuanbghtambah" element={<PengajuanBghPeroranganTambah />} />

                        <Route path="perorangan/penugasanbgh" element={<PenugasanBghPerorangan />} />

                        <Route path="perorangan/penugasanbghtambah" element={<PenugasanBghPeroranganTambah />} />

                        <Route path="perorangan/profiledokumen" element={<ProfileDokumen />} />

                        
           
                    </Route>
                </Routes>
            </>
        )

    } else if (user.roles.includes("ROLE_IGAHP_TA_BGH")) {
        return (
            <Routes>
                <Route element={<MenuTaBgh />} >
                    <Route index element={<TaBghContentDepan />} />
                    <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                </Route>
            </Routes>
        )
    } else if (user.role === "IGAHP_ROLE_USER") {
        return (

            <Routes>
                {/* <Route element={<MenuAsesi />}>
                        <Route index element={<ContentDashboard />} />
                        <Route path="ContentDashboard" element={<ContentDashboard />} />
                        <Route path="ContentProfile" element={<ContentProfile />} />
                        <Route path="ContentBidangSertifikasi" element={<ContentBidangSertifikasi />} />
                        <Route path="ContentKompetensi" element={<ContentKompetensi />} />
                        <Route path="ContentOkupansi" element={<ContentOkupansi />} />
                        <Route path="ContentUpdateDataPribadi" element={<ContentUpdateDataPribadi />} />
                        <Route path="ContentProgramSertifikasi" element={<ContentProgramSertifikasi />} />
                        <Route path="ContentKelengkapanDokumen" element={<ContentKelengkapanDokumen />} />
                    </Route> */}
            </Routes>

        )
    } else if (user.roles.includes("ROLE_IGAHP_BULAIN")) {
        return (
            <div className="wrapper">
                <Routes>
                    <Route element={<MenuBank />} >

                        <Route index element={<BankContentDepan />} />
                        <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                        <Route path="bank/BankDataCompare" element={<BankDataCompare />} />
                        <Route path="bank/dashboard" element={<BankDashboard />} />

                        <Route path="bank/BankProfile" element={<BankProfile />} />
                        <Route path="bank/BankDataSlik" element={<BankDataSlik />} />



                        {/* <Route path="pengembang/PengembangAdminListLokasi" element={<PengembangAdminListLokasi />} />
                    <Route path="pengembang/PengembangLokasiTambah" element={<PengembangLokasiTambah />} />                    
                    <Route path="pengembang/PengembangLokasiProfile" element={<PengembangLokasiProfile />} />
                    <Route path="pengembang/PengembangKantorPemasaran" element={<PengembangKantorPemasaran />} />
                    <Route path="pengembang/PengembangKantorPemasaranTambah" element={<PengembangKantorPemasaranTambah />} />
                    <Route path="pengembang/PengembangLokasiSitePlan" element={<PengembangLokasiSitePlan />} />
                    <Route path="pengembang/PengembangLokasiTipeRumah" element={<PengembangLokasiTipeRumah />} />
                    <Route path="pengembang/PengembangLokasiTipeRumahTambah" element={<PengembangLokasiTipeRumahTambah />} />
                    <Route path="pengembang/PengembangLokasiBlok" element={<PengembangLokasiBlok />} />
                    <Route path="pengembang/PengembangLokasiBlokTambah" element={<PengembangLokasiBlokTambah />} />
                    <Route path="pengembang/PengembangSurvey" element={<PengembangSurvey />} /> */}



                    </Route>
                </Routes>
            </div>
        )
    } else if (user.roles.includes("ROLE_IGAHP_SURVEYOR")) {
        return (

            <Routes>
                <Route element={<MenuSurveyor />} >

                    <Route index element={<SurveyorContentDepan />} />
                    <Route path="pemda/dashboard" element={<PemdaDashboard />} />
                    <Route path="pemda/ListPengajuanBgh" element={<ListPengajuanBgh />} />
                    <Route path="pemda/PengajuanBghDetail" element={<PengajuanBghDetail />} />
                    <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<ListPendataanBgh />} /> */}
                    <Route path="pemda/ListPendataanBgh" element={<IgahpDataQuesionerTable2 />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<DataTableWithEdit />} /> */}
                    <Route path="pemda/LolosSubsidiChecking" element={<LolosSubsidiChecking />} />
                    <Route path="surveyor/SurveyorDataSiapSurvei" element={<SurveyorDataSiapSurvei />} />
                    <Route path="pemda/IgahpBelumSubsidiChecking" element={<IgahpBelumSubsidiChecking />} />
                    <Route path="pemda/Toast" element={<ToastTes />} />
                    <Route path="surveyor/SurveyorHasilSurvei" element={<SurveyorHasilSurvei />} />
                    <Route path="surveyor/SurveyorAnalisaSurvei" element={<SurveyorAnalisaSurvei />} />
                    <Route path="surveyor/SurveyorDashboard" element={<SurveyorDashboard />} />
                    <Route path="surveyor/RptPerformance1" element={<RptPerformance1 />} />
                    {/* <Route path="surveyor/GeoTagTest" element={<GeoTagTest />} /> */}
                    

                    

                    

                </Route>
            </Routes>

        )
    } else if (user.roles.includes("ROLE_IGAHP_PEMDA")) {
        return (

            <Routes>
                <Route element={<MenuPemda />} >

                    <Route index element={<PemdaContentDepan />} />
                    <Route path="pemda/dashboard" element={<PemdaDashboard />} />
                    <Route path="pemda/ListPengajuanBgh" element={<ListPengajuanBgh />} />
                    <Route path="pemda/PengajuanBghDetail" element={<PengajuanBghDetail />} />
                    <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<ListPendataanBgh />} /> */}
                    <Route path="pemda/ListPendataanBgh" element={<IgahpDataQuesionerTable2 />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<DataTableWithEdit />} /> */}
                    <Route path="pemda/LolosSubsidiChecking" element={<LolosSubsidiChecking />} />
                    <Route path="pemda/IgahpDataSiapSurvei" element={<IgahpDataSiapSurvei />} />
                    <Route path="pemda/SubsidiCheckingResume" element={<SubsidiCheckingResume />} />
                    <Route path="pemda/IgahpBelumSubsidiChecking" element={<IgahpBelumSubsidiChecking />} />
                    <Route path="pemda/TidakLolosSubsidiChecking" element={<TidakLolosSubsidiChecking />} />
                    <Route path="pemda/QuesionerDataAwal" element={<QuesionerDataAwal />} />
                    <Route path="pemda/Toast" element={<ToastTes />} />
                    <Route path="pemda/IgahpDataHasilSurvei" element={<IgahpDataHasilSurvei />} />
                    <Route path="pemda/IgahpDataAnalisaHasilSurvei" element={<IgahpDataAnalisaHasilSurvei />} />
                    <Route path="pemda/PemdaApprovalPendataan" element={<PemdaApprovalPendataan />} />
                    <Route path="pemda/PemdaPenugasanPendataan" element={<PemdaPenugasanPendataan />} />
                    <Route path="pemda/RptPerformanceSurveyor1" element={<RptPerformanceSurveyor1 />} />
                    <Route path="pemda/RptPerformanceSurveyorKelurahan" element={<RptPerformanceSurveyorKelurahan />} />
                    <Route path="pemda/RptPerformanceSurveyorKecamatan" element={<RptPerformanceSurveyorKecamatan />} />
                    <Route path="pemda/RptPerformanceSurveyorWilayah" element={<RptPerformanceSurveyorWilayah />} />
                    <Route path="pemda/ManajemenSurveyor" element={<ManajemenSurveyor />} />
                    <Route path="pemda/RptAlamatPemda" element={<RptAlamatPemda />} />
                    
                    
                </Route>
            </Routes>

        )
    } else if (user.roles.includes("ROLE_IGAHP_PIMPINAN")) {
        return (
            <Routes>
                <Route element={<MenuPimpinan />} >
                    <Route index element={<PimpinanContentDepan />} />
                    <Route path="pimpinan/dashboard" element={<PimpinanDashboard />} />
                    <Route path="pemda/ListPengajuanBgh" element={<ListPengajuanBgh />} />
                    <Route path="pemda/PengajuanBghDetail" element={<PengajuanBghDetail />} />
                    <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<ListPendataanBgh />} /> */}
                    <Route path="pemda/ListPendataanBgh" element={<IgahpDataQuesionerTable2 />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<DataTableWithEdit />} /> */}
                    <Route path="pemda/LolosSubsidiChecking" element={<LolosSubsidiChecking />} />
                    <Route path="pemda/IgahpDataSiapSurvei" element={<IgahpDataSiapSurvei />} />
                    <Route path="pemda/SubsidiCheckingResume" element={<SubsidiCheckingResume />} />
                    <Route path="pemda/IgahpBelumSubsidiChecking" element={<IgahpBelumSubsidiChecking />} />
                    <Route path="pemda/TidakLolosSubsidiChecking" element={<TidakLolosSubsidiChecking />} />
                    <Route path="pemda/QuesionerDataAwal" element={<QuesionerDataAwal />} />
                    <Route path="pemda/Toast" element={<ToastTes />} />
                    <Route path="pemda/IgahpDataHasilSurvei" element={<IgahpDataHasilSurvei />} />
                    <Route path="pemda/IgahpDataAnalisaHasilSurvei" element={<IgahpDataAnalisaHasilSurvei />} />
                    <Route path="pemda/PemdaApprovalPendataan" element={<PemdaApprovalPendataan />} />
                    <Route path="pemda/PemdaPenugasanPendataan" element={<PemdaPenugasanPendataan />} />

                    <Route path="nasional/NasionalPerkotaan" element={<NasionalPerkotaan />} />

                    
                    
                </Route>
            </Routes>

        )
    } else if (user.roles.includes("ROLE_IGAHP_MONEVSTUDIO")) {
        return (
            <Routes>
                <Route element={<MenuMonevStudio />} >
                    <Route index element={<MonevStudioContentDepan />} />
                    <Route path="pimpinan/dashboard" element={<MonevStudioDashboard />} />
                    <Route path="pemda/ListPengajuanBgh" element={<ListPengajuanBgh />} />
                    <Route path="pemda/PengajuanBghDetail" element={<PengajuanBghDetail />} />
                    <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<ListPendataanBgh />} /> */}
                    <Route path="pemda/ListPendataanBgh" element={<IgahpDataQuesionerTable2 />} />
                    {/* <Route path="pemda/ListPendataanBgh" element={<DataTableWithEdit />} /> */}
                    <Route path="pemda/LolosSubsidiChecking" element={<LolosSubsidiChecking />} />
                    <Route path="pemda/IgahpDataSiapSurvei" element={<IgahpDataSiapSurvei />} />
                    <Route path="pemda/SubsidiCheckingResume" element={<SubsidiCheckingResume />} />
                    <Route path="pemda/IgahpBelumSubsidiChecking" element={<IgahpBelumSubsidiChecking />} />
                    <Route path="pemda/TidakLolosSubsidiChecking" element={<TidakLolosSubsidiChecking />} />
                    <Route path="pemda/QuesionerDataAwal" element={<QuesionerDataAwal />} />
                    <Route path="pemda/Toast" element={<ToastTes />} />
                    <Route path="pemda/IgahpDataHasilSurvei" element={<IgahpDataHasilSurvei />} />
                    <Route path="pemda/IgahpDataAnalisaHasilSurvei" element={<IgahpDataAnalisaHasilSurvei />} />
                    <Route path="pemda/PemdaApprovalPendataan" element={<PemdaApprovalPendataan />} />
                    <Route path="pemda/PemdaPenugasanPendataan" element={<PemdaPenugasanPendataan />} />

                    <Route path="nasional/NasionalPerkotaan" element={<NasionalPerkotaan />} />

                    
                    
                </Route>
            </Routes>

        )    
    } else if (user.roles.includes("ROLE_IGAHP_PEMOHON")) {
        return (

            <Routes>
                <Route element={<MenuPemohon />} >

                    <Route index element={<PemohonContentDepan />} />
                    <Route path="pemohon/ListPermohonan" element={<ListPermohonan />} />
                    <Route path="pemda/PengajuanBghDetail" element={<PengajuanBghDetail />} />
                    <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />

                </Route>
            </Routes>

        )
    } else if (user.roles.includes("ROLE_IGAHP_SEKRETARIAT_BGH")) {
        return (
            <div className="wrapper">
                <Routes>
                    <Route element={<MenuSekBgh />} >

                        <Route index element={<SekBghContentDepan />} />
                        <Route path="tabgh/TaBghHasilPenilaian" element={<TaBghHasilPenilaian />} />
                        <Route path="bank/BankDataCompare" element={<BankDataCompare />} />


                    </Route>
                </Routes>
            </div>
        )
    }

}




export default MainMenu
