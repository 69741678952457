import React from 'react'

import { AuthContext } from '../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';

import ModalUploadCSV from './childs/ModalUploadCSV';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import LocationSelect from '../shared/LocationSelect'
import LocationSelect2 from '../shared/LocationSelect2'
import EXIF from 'exif-js';
import validator from 'validator';


const UploadRumahquCsv = () => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  const [geoData, setGeoData] = useState({
    photoDiri: null,
    photoSekitar: null,
    photoRumah: null,
    photoLahan: null
  });


  const { user, setUser } = useContext(AuthContext);
  let refreshToken = null;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate()
  const { loginContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);

  const [isModalUpload, setModalUpload] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [editingData, setEditingData] = useState(null);

  const [viewOnly, setViewOnly] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedProvince, setSelectedProvince] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedVillage, setSelectedVillage] = useState('');
  const [wilayahPilih, setWilayahPilih] = useState('');

  const [selectedProvince2, setSelectedProvince2] = useState('');
  const [selectedCity2, setSelectedCity2] = useState('');
  const [selectedDistrict2, setSelectedDistrict2] = useState('');
  const [selectedVillage2, setSelectedVillage2] = useState('');
  const [wilayahPilih2, setWilayahPilih2] = useState('');

  const [activeTab, setActiveTab] = useState('first');
  const [previewImages, setPreviewImages] = useState({});
  const [editData, setEditData] = useState({ id: '', nik: '', namaLengkap: '', handphone: '' });

  const [showModal, setShowModal] = useState(false);


  const handleAddClick = () => {
    setEditingData(null);
    setModalUpload(true);
  }



  const handleShowModal = () => {
    setShowModal(true);
  };

  const [selectedFiles, setSelectedFiles] = useState({
    photoDiri: null,
    photoSekitar: null,
    photoRumah: null,
    photoLahan: null
  });



  const handleVillageChange = (villageId) => {
    setSelectedVillage(villageId);
    setEditData(prevData => ({
      ...prevData,
      kodeWilayah: villageId
    }));

  };


  const handleModalClose = () => {
    setShowModal(false);
  };



  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setEditData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };


  useEffect(() => {
    if (wilayahPilih2) {
      fetchData();
    }
  }, [page, perPage, searchQuery, wilayahPilih2]); // Consolidated dependencies



  useEffect(() => {
    if (selectedProvince2) {
      setWilayahPilih2(selectedProvince2)
    }
  }, [selectedProvince2]);

  useEffect(() => {
    if (selectedCity2) {
      setWilayahPilih2(selectedCity2)
    }
  }, [selectedCity2]);

  useEffect(() => {
    if (selectedDistrict2) {
      setWilayahPilih2(selectedDistrict2)
    }
  }, [selectedDistrict2]);

  useEffect(() => {
    if (selectedVillage2) {
      setWilayahPilih2(selectedVillage2)
    }
  }, [selectedVillage2]);




  const columns = [

    {
      name: 'No.',
      selector: (row, index) => (page - 1) * perPage + index + 1,
      sortable: true,
      width: '80px', // Set a reasonable width for small columns
    },
    {
      name: 'Kab/Kota',
      selector: row => row.namaKabkota,
      sortable: true,
      wrap: true, // Wrap text in case it overflows
    },

    {
      name: 'Kecamatan',
      selector: row => row.namaKec,
      sortable: true,
      wrap: true, // Wrap text in case it overflows
    },
    {
      name: 'Nama Lengkap',
      selector: row => row.namaLengkap,
      sortable: true,
      wrap: true, // Wrap text in case it overflows
    },
    {
      name: 'NIK',
      selector: row => {
        const nik = row.nik;
        return nik ? nik.slice(0, 12) + nik.slice(12).replace(/\d/g, '*') : '';
      },
      sortable: true,
      wrap: true,
    },
    {
      name: 'Jenis Kelamin',
      selector: row => row.jenisKelamin == '1' ? 'Pria' : row.jenisKelamin == '2' ? 'Wanita' : 'Unknown',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Alamat',
      selector: row => row.alamatKtp,
      sortable: true,
      wrap: true, // Wrap text in case it overflows
    },
    // {
    //     name: 'Status',
    //     selector: row => row.status == '2a' ? 'Lolos Checking' : row.status == '2b' ? 'Tidak Lolos Checking' : 'Unknown',
    //     sortable: true,
    //     wrap: true,
    // },

    {
      name: 'Dibuat oleh',
      selector: row => row.username,
      sortable: true,
      wrap: true, // Wrap text in case it overflows
    },

    {
      name: 'Status',
      selector: row => {
        switch (row.status) {
          case '0':
            return 'Data Awal';
          case '1':
            return 'Proses Checking';
          case '2a':
            return 'Lolos Checking';
          case '2b':
            return 'Tidak Lolos Checking';
          case '2c':
              return 'Gagal Checking';
          case '3':
            return 'Menunggu Approval';
          case '4':
            return 'Revisi';
          case '5':
            return 'Approved';
          default:
            return 'Unknown';
        }
      },
      sortable: true,
      wrap: true, // Wrap text in case it overflows
      cell: row => {
        let statusText;
        let badgeClass;

        switch (row.status) {
          case '0':
            statusText = 'Data Awal';
            badgeClass = 'badge badge-primary'; // Yellow color for pending
            break;
          case '1':
            statusText = 'Proses Checking';
            badgeClass = 'badge badge-warning'; // Yellow color for pending
            break;
          case '2a':
            statusText = 'Lolos Checking';
            badgeClass = 'badge badge-warning'; // Yellow color for pending
            break;
          case '2b':
            statusText = 'Tidak Lolos Checking';
            badgeClass = 'badge badge-danger'; // Red color for revision
            break;
          case '3':
            statusText = 'Menunggu Approval';
            badgeClass = 'badge badge-secondary'; // Red color for revision
            break;
          case '4':
            statusText = 'Revisi';
            badgeClass = 'badge badge-danger'; // Green color for approval
            break;
          case '5':
            statusText = 'Approved';
            badgeClass = 'badge badge-success'; // Green color for approval
            break;
          default:
            statusText = 'Unknown';
            badgeClass = 'badge badge-secondary'; // Grey color for unknown
        }

        // Return the status text inside a Bootstrap badge
        return <span className={badgeClass}>{statusText}</span>;
      }
    },


    {
      name: 'Aksi',
      cell: (row) => (
        <div>
          <button
            className="btn btn-info btn-sm"
            onClick={() => {
              setViewOnly(true);
              handleEdit(row);
            }}>
            <i className="fa fa-eye"></i>
            {/* View */}
          </button>
          {/* <button
                    className="btn btn-primary btn-sm ml-2"
                    onClick={() => {
                        setViewOnly(false);
                        handleEdit(row);
                    }}>
                    <i class="bi bi-pencil-fill"></i>

                </button> */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '150px', // Adjusted width to accommodate both buttons
    }



  ];


  const handleEdit = (row) => {
    const formattedTanggalLahir = new Date(row.tanggalLahir).toISOString().split('T')[0];

    setGeoData({})
    setSelectedVillage(row.kodewilayah);

    setPreviewImages({
      photoDiri: row.photoDiriPath
        ? `${apiUrl}/api/public/displayimage/${row.photoDiriPath}`
        : '/dist/img/no-photo-choose.png',
      photoSekitar: row.photoSekitarPath
        ? `${apiUrl}/api/public/displayimage/${row.photoSekitarPath}`
        : '/dist/img/no-photo-choose.png',
      photoRumah: row.photoRumahPath
        ? `${apiUrl}/api/public/displayimage/${row.photoRumahPath}`
        : '/dist/img/no-photo-choose.png',
      photoLahan: row.photoLahanPath
        ? `${apiUrl}/api/public/displayimage/${row.photoLahanPath}`
        : '/dist/img/no-photo-choose.png'
    });

    setEditData(
      {
        id: row.id,
        namaDesa: row.namaDesa,
        rw: row.rw,
        rt: row.rt,
        alamatKtp: row.alamatKtp,
        alamatDomisili: row.alamatDomisili,
        nik: row.nik,
        namaLengkap: row.namaLengkap,
        nomorHandphone: row.nomorHandphone,
        nomorKk: row.nomorKk,
        jenisKelamin: row.jenisKelamin,
        tanggalLahir: formattedTanggalLahir,
        statusKawin: row.statusKawin,
        agama: row.agama,
        pendidikan: row.pendidikan,
        pekerjaan: row.pekerjaan,
        penghasilan: row.penghasilan,
        memilikiTabungan: row.memilikiTabungan,
        statusKepemilikanRumah: row.statusKepemilikanRumah,
        buktiKepemilikanRumah: row.buktiKepemilikanRumah,
        jumlahPenghuni: row.jumlahPenghuni,
        luasTanah: row.luasTanah,
        luasBangunan: row.luasBangunan,
        jenisAtap: row.jenisAtap,
        jenisDinding: row.jenisDinding,
        jenisLantai: row.jenisLantai,
        penerangan: row.penerangan,
        airMinum: row.airMinum,
        jenisKloset: row.jenisKloset,
        bbMemasak: row.bbMemasak,
        layakKonstruksi: row.layakKonstruksi,
        layakDensitas: row.layakDensitas,
        layakAirminum: row.layakAirminum,
        layakSanitasi: row.layakSanitasi,
        asetLahanlain: row.asetLahanlain,
        asetRumahlain: row.asetRumahlain,
        minatProgrampembiayaan: row.minatProgrampembiayaan,
        jenisPeminatanrumah: row.jenisPeminatanrumah,
        programPerumahan: row.programPerumahan,
        pilihanBankpelaksana: row.pilihanBankpelaksana,
        besaranCicilan: row.besaranCicilan,
        rencanaProgram: row.rencanaProgram,
        angkutanUmum: row.angkutanUmum,
        terminal: row.terminal,
        stasiun: row.stasiun,
        pasar: row.pasar,
        bank: row.bank,
        gerbangTol: row.gerbangTol,
        spbu: row.spbu,
        tk: row.tk,
        sd: row.sd,
        sltp: row.sltp,
        slta: row.slta,
        universitas: row.universitas,
        pendidikanLainnya: row.pendidikanLainnya,
        masjidMusholla: row.masjidMusholla,
        gereja: row.gereja,
        vihara: row.vihara,
        klenteng: row.klenteng,
        tempatIbadahLainnya: row.tempatIbadahLainnya,
        terjadiGenanganAir: row.terjadiGenanganAir,
        terjadiBanjir: row.terjadiBanjir,
        terjadiPutingBeliung: row.terjadiPutingBeliung,
        terjadiKeretakanTanah: row.terjadiKeretakanTanah,
        terjadiLongsor: row.terjadiLongsor,
        terjadiGempaBumi: row.terjadiGempaBumi,
        namaBankLain: row.namaBankLain,
        koordinatFotoDiri: row.koordinatFotoDiri,
        koordinatFotoLahan: row.koordinatFotoLahan,
        koordinatFotoRumah: row.koordinatFotoRumah,
        koordinatFotoSekitar: row.koordinatFotoSekitar
      }
    );


    setShowModal(true);
  };


  const fetchData = async () => {
    setLoading(true);


    try {
      const sanitizedSearchQuery = validator.escape(searchQuery); // Escape query untuk keamanan

      const response = await axios.get(`${apiUrl}/api/igahp/quesioner/allDataRumahQu`, {
        params: {
          page: page - 1,
          size: perPage,
          wilayah: wilayahPilih2, // Kirim wilayah yang sudah digabung
          search: encodeURIComponent(sanitizedSearchQuery), // Encode search query
        },
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      // console.log(response.data)
      setData(response.data.content);
      setTotalRows(response.data.totalElements);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
    // setSelectedProvince("35")
  };


  useEffect(() => {
    fetchData();
  }, [page, perPage, searchQuery]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const handleModalSave = async () => {


    if (!selectedVillage) {
      alert('Kode wilayah harus dipiih');
      return

    }

    if (!editData.nik) {
      alert('Nik harus diisi');
      return
    }

    if (!editData.namaLengkap) {
      alert('Nama harus diisi');
      return
    }


    if (editData.nik.length != 16) {
      alert('Nik harus 16 digit');
      return
    }


    if (!editData.namaLengkap) {
      alert('Nama harus di isi');
      return
    }

    if (!editData.nomorHandphone) {
      alert('Nomor Handphone harus diisi');
      return
    }
    // if (!editData.nomorKk) {
    //     alert('Nomor KK harus diisi');
    //     return
    // }

    // if (editData.nomorKk.length != 16) {
    //     alert('Nomor KK harus 16 digit');
    //     return
    // }

    if (!editData.jenisKelamin) {
      alert('Jenis Kelamin harus diisi');
      return
    }

    if (editData.jenisKelamin.length < 1) {
      alert('Jenis Kelamin harus diisi');
      return
    }

    // Check if any selected file is missing geoData
    // if (selectedFiles.photoDiri && !geoData.photoDiri) {
    //     alert('GeoTag untuk foto diri tidak ditemukan');
    //     return;
    // }
    // if (selectedFiles.photoSekitar && !geoData.photoSekitar) {
    //     alert('GeoTag untuk foto sekitar tidak ditemukan');
    //     return;
    // }
    // if (selectedFiles.photoRumah && !geoData.photoRumah) {
    //     alert('GeoTag untuk foto rumah tidak ditemukan');
    //     return;
    // }
    // if (selectedFiles.photoLahan && !geoData.photoLahan) {
    //     alert('GeoTag untuk foto lahan tidak ditemukan');
    //     return;
    // }

    setLoading(true); // Show the spinner

    const formData = new FormData();
    if (selectedFiles.photoDiri) {
      formData.append('photoDiri', selectedFiles.photoDiri);
      // Append geoData for photoDiri
      if (geoData.photoDiri) {
        formData.append('photoDiriLat', geoData.photoDiri.lat);
        formData.append('photoDiriLon', geoData.photoDiri.lon);
      }
    }
    if (selectedFiles.photoSekitar) {
      formData.append('photoSekitar', selectedFiles.photoSekitar);
      // Append geoData for photoSekitar
      if (geoData.photoSekitar) {
        formData.append('photoSekitarLat', geoData.photoSekitar.lat);
        formData.append('photoSekitarLon', geoData.photoSekitar.lon);
      }
    }
    if (selectedFiles.photoRumah) {
      formData.append('photoRumah', selectedFiles.photoRumah);
      // Append geoData for photoRumah
      if (geoData.photoRumah) {
        formData.append('photoRumahLat', geoData.photoRumah.lat);
        formData.append('photoRumahLon', geoData.photoRumah.lon);
      }
    }
    if (selectedFiles.photoLahan) {
      formData.append('photoLahan', selectedFiles.photoLahan);
      // Append geoData for photoLahan
      if (geoData.photoLahan) {
        formData.append('photoLahanLat', geoData.photoLahan.lat);
        formData.append('photoLahanLon', geoData.photoLahan.lon);
      }
    }

    try {
      // First request: update `editData`
      const semuaResponse = await axios.put(`${apiUrl}/api/igahp/quesioner/updateSemua/${editData.id}`, editData, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      // Second request: update photos and geotags
      await axios.put(`${apiUrl}/api/igahp/quesioner/updatePhoto/${semuaResponse.data.data}`,
        formData, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      await axios.put(`${apiUrl}/api/igahp/quesioner/updateStatus/${semuaResponse.data.data}/3`,
        {}, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'Content-Type': 'multipart/form-data'

        }
      });


      // Close modal and refresh data
      setShowModal(false);
      fetchData();

    } catch (error) {
      console.error('Error updating data:', error);
    } finally {
      setLoading(false); // Hide the spinner
    }
  };

  // const handleExportCSV = async () => {
  //     const allData = await fetchAllData();
  //     const headers = ['No.', 'Nama Lengkap', 'NIK', 'Jenis Kelamin', 'Tanggungan Jiwa', 'Handphone'];
  //     const itemsFormatted = allData.map((item, index) => ({
  //         'No.': index + 1,
  //         'Nama Lengkap': item.namaLengkap,
  //         'NIK': item.nik,
  //         'Jenis Kelamin': item.jenisKelamin,
  //         'Tanggungan Jiwa': item.tanggunganJiwa,
  //         'Handphone': item.handphone,
  //     }));

  //     exportCSV({ data: itemsFormatted, filename: 'pendataan_awal_bgh_all', headers });
  // };

  const handleImageChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {

      const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
      if (fileSize > 10) {
        alert("Ukuran file tidak boleh lebih dari 5MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFiles(prevState => ({
          ...prevState,
          [fieldName]: file
        }));
        setPreviewImages(prevState => ({
          ...prevState,
          [fieldName]: reader.result // Store the preview image data URL
        }));
      };
      reader.readAsDataURL(file); // Read the file as a data URL

      EXIF.getData(file, function () {
        const latitude = EXIF.getTag(this, 'GPSLatitude');
        const longitude = EXIF.getTag(this, 'GPSLongitude');
        const latitudeRef = EXIF.getTag(this, 'GPSLatitudeRef');
        const longitudeRef = EXIF.getTag(this, 'GPSLongitudeRef');

        if (latitude && longitude) {
          const lat = convertDMSToDD(latitude, latitudeRef);
          const lon = convertDMSToDD(longitude, longitudeRef);

          // Update geoData with the geolocation information
          setGeoData(prevState => ({
            ...prevState,
            [fieldName]: {
              lat,
              lon,
              latitudeRef,
              longitudeRef
            }
          }));

          console.log('Geo data....')
          console.log(geoData)
        } else {
          alert('This photo does not contain geotag (location) information.');
        }
      });
    }
  };

  // Utility function to convert DMS (Degrees, Minutes, Seconds) to Decimal Degrees (DD)
  const convertDMSToDD = (dms, ref) => {
    const degrees = dms[0] + dms[1] / 60 + dms[2] / 3600;
    return ref === 'S' || ref === 'W' ? -degrees : degrees;
  };






  // Function to close the modal
  const handleModalUploadClose = () => {
    console.log('Modal Close')
    setModalUpload(false);
    fetchData();

  };




  let sequenceNumber = 1;

  return (

    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Data RumahQu</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Data RumahQu</li>
                </ol>
              </div>
            </div>

            <div className="form-group">
              <h8>Pilih Wilayah</h8>
              <div className="row">
                <LocationSelect2
                  onProvinceChange={setSelectedProvince2}
                  onCityChange={setSelectedCity2}
                  onDistrictChange={setSelectedDistrict2}
                  onVillageChange={setSelectedVillage2}
                />
              </div>
            </div>

            <div className="row mb-2 d-flex justify-content-between">
              <div className="col-sm-4 d-flex">
                <input
                  type="text"
                  placeholder="Search..."
                  className="form-control form-control-sm"
                  value={searchQuery}
                  onChange={handleSearch}
                />
                <button
                  type="button"
                  id="tambahModal" data-toggle="modal"
                  data-target="#ModalUploadCSV"
                  className="btn btn-success float-left btn-sm"
                  onClick={handleAddClick}
                >Upload</button>
              </div>
            </div>


            <div className="d-flex justify-content-between align-items-center mb-2">



            </div>

            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={page => setPage(page)}
              onChangeRowsPerPage={perPage => setPerPage(perPage)}
              responsive
              dense
            />
          </div>
        </section>
      </div>

      <Modal show={showModal} onHide={handleModalClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {viewOnly ? "View Only" : editData.id === "0" ? "Tambah Data" : "Edit Data"}
          </Modal.Title>
        </Modal.Header>


        <Modal.Body>
          <Tabs
            id="edit-data-tabs"
            activeKey={activeTab}
            onSelect={(k) => setActiveTab(k)}
            className="mb-3"
          >
            <Tab eventKey="first" title="Wilayah">
              <Form>

                <div className="form-group">
                  <label>Kelurahan</label>
                  <div className="row">
                    <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                  </div>
                </div>


                <Form.Group controlId="formNmDesa">
                  <Form.Label>Nama Desa</Form.Label>
                  <Form.Control
                    type="text"
                    name="namaDesa"
                    value={editData.namaDesa}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNmRt">
                  <Form.Label>RT</Form.Label>
                  <Form.Control
                    type="text"
                    name="rt"
                    value={editData.rt}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNmRw">
                  <Form.Label>RW</Form.Label>
                  <Form.Control
                    type="text"
                    name="rw"
                    value={editData.rw}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="formAlamatKtp">
                  <Form.Label>Alamat Sesuai Ktp</Form.Label>
                  <Form.Control
                    type="text"
                    name="alamatKtp"
                    value={editData.alamatKtp}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formAlamatDomisili">
                  <Form.Label>Alamat Domisili</Form.Label>
                  <Form.Control
                    type="text"
                    name="alamatDomisili"
                    value={editData.alamatDomisili}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Form>
            </Tab>

            <Tab eventKey="second" title="Demografi">
              <Form>
                <Form.Group controlId="formNama">
                  <Form.Label>Nama Lengkap</Form.Label>
                  <Form.Control
                    type="text"
                    name="namaLengkap"
                    value={editData.namaLengkap}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="formNik">
                  <Form.Label>Nik</Form.Label>
                  {editData.id == "0" ? (
                    <Form.Control
                      type="text"
                      name="nik"
                      value={editData.nik}
                      onChange={handleInputChange}
                    />
                  ) : (
                    <Form.Control
                      type="text"
                      name="nik"
                      value={`${editData.nik.slice(0, 12)}****`}
                      readOnly
                    />
                  )}
                </Form.Group>


                <Form.Group controlId="formKk">
                  <Form.Label>Nomor KK</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomorKk"
                    value={editData.nomorKk}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formHp">
                  <Form.Label>Nomor Hp</Form.Label>
                  <Form.Control
                    type="text"
                    name="nomorHandphone"
                    value={editData.nomorHandphone}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formJenisKelamin">
                  <Form.Label>Jenis Kelamin</Form.Label>
                  <Form.Select
                    name="jenisKelamin"
                    value={editData.jenisKelamin}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Jenis Kelamin</option>
                    <option value="1">Laki-laki</option>
                    <option value="2">Perempuan</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formTanggalLahir">
                  <Form.Label>Tanggal Lahir</Form.Label>
                  <Form.Control
                    type="date"
                    name="tanggalLahir"
                    value={editData.tanggalLahir}
                    onChange={handleInputChange}
                  />
                </Form.Group>


                {/* Status Perkawinan */}
                <Form.Group controlId="formStatusPerkawinan">
                  <Form.Label>Status Perkawinan</Form.Label>
                  <Form.Select
                    name="statusKawin"
                    value={editData.statusKawin}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Status Perkawinan</option>
                    <option value="1">Belum Kawin</option>
                    <option value="2">Kawin</option>
                    <option value="3">Cerai Hidup</option>
                    <option value="4">Cerai Mati</option>
                  </Form.Select>
                </Form.Group>

                {/* Agama */}
                <Form.Group controlId="formAgama">
                  <Form.Label>Agama</Form.Label>
                  <Form.Select
                    name="agama"
                    value={editData.agama}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Agama</option>
                    <option value="1">Islam</option>
                    <option value="2">Kristen</option>
                    <option value="3">Katolik</option>
                    <option value="4">Hindu</option>
                    <option value="5">Buddha</option>
                    <option value="6">Konghucu</option>
                    <option value="7">Penghayat Kepercayaan</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formPendidikan">
                  <Form.Label>Pendidikan</Form.Label>
                  <Form.Control
                    as="select"
                    name="pendidikan"
                    value={editData.pendidikan}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Pendidikan</option>
                    <option value="1">Tidak/Belum Sekolah</option>
                    <option value="2">Tidak Tamat SD/Sederajat</option>
                    <option value="3">Masih SD/Sederajat</option>
                    <option value="4">Tamat SD/Sederajat</option>
                    <option value="5">Masih SLTP/Sederajat</option>
                    <option value="6">Tamat SLTP/Sederajat</option>
                    <option value="7">Masih SLTA/Sederajat</option>
                    <option value="8">Tamat SLTA/Sederajat</option>
                    <option value="9">Masih PT/Sederajat</option>
                    <option value="10">Tamat PT/Sederajat</option>
                  </Form.Control>
                </Form.Group>


                <Form.Group controlId="formPekerjaan">
                  <Form.Label>Pekerjaan</Form.Label>
                  <Form.Select
                    name="pekerjaan"
                    value={editData.pekerjaan}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Pekerjaan</option>
                    <option value="1">1. Tidak/Belum Bekerja</option>
                    <option value="2">2. Petani</option>
                    <option value="3">3. Nelayan</option>
                    <option value="4">4. Pedagang</option>
                    <option value="5">5. Pejabat Negara</option>
                    <option value="6">6. PNS/TNI/Polri</option>
                    <option value="7">7. Pegawai Swasta</option>
                    <option value="8">8. Wiraswasta</option>
                    <option value="9">9. Pensiunan</option>
                    <option value="10">10. Pekerja Lepas</option>
                  </Form.Select>
                </Form.Group>


                <Form.Group controlId="formPenghasilan">
                  <Form.Label>Penghasilan</Form.Label>
                  <Form.Control
                    type="text"
                    name="penghasilan"
                    value={editData.penghasilan}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="formRekeningTabungan">
                  <Form.Label>Memiliki Rekening Tabungan</Form.Label>
                  <Form.Select
                    name="memilikiTabungan"
                    value={editData.memilikiTabungan}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </Tab>

            <Tab eventKey="third" title="Kepemilikan Rumah">
              <Form>
                {/* Status Kepemilikan Bangunan */}
                <Form.Group controlId="formStatusKepemilikan">
                  <Form.Label>Status Kepemilikan Bangunan tempat tinggal yang ditempati </Form.Label>
                  <Form.Select
                    name="statusKepemilikanRumah"
                    value={editData.statusKepemilikanRumah}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Milik Sendiri</option>
                    <option value="2">2. Kontrak/Sewa</option>
                    <option value="3">3. Bebas Sewa</option>
                    <option value="4">4. Menumpang</option>
                    <option value="5">5. Dinas</option>
                    <option value="6">6. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Jenis Bukti Kepemilikan */}
                <Form.Group controlId="formJenisBuktiKepemilikan">
                  <Form.Label>(Jika Ya) Apa jenis bukti kepemilikan tanah bangunan tempat tinggal ini </Form.Label>
                  <Form.Select
                    name="buktiKepemilikanRumah"
                    value={editData.buktiKepemilikanRumah}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. SHM atas Nama Anggota Keluarga</option>
                    <option value="2">2. SHM bukan a.n Anggota Keluarga dengan perjanjian pemanfaatan tertulis</option>
                    <option value="3">3. SHM bukan a.n Anggota Keluarga tanpa perjanjian pemanfaatan tertulis</option>
                    <option value="4">4. Sertifikat selain SHM (SHGB, SHSRS)</option>
                    <option value="5">5. Surat Bukti Lainnya (Girik, Letter C, dll)</option>
                    <option value="6">6. Tidak Punya</option>
                  </Form.Select>
                </Form.Group>

                {/* Jumlah Penghuni */}
                <Form.Group controlId="formJumlahPenghuni">
                  <Form.Label>Jumlah Penghuni (masukkan jumlah)</Form.Label>
                  <Form.Control
                    type="number"
                    name="jumlahPenghuni"
                    value={editData.jumlahPenghuni}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {/* Luas Tanah */}
                <Form.Group controlId="formLuasTanah">
                  <Form.Label>Luas Tanah (m2)</Form.Label>
                  <Form.Control
                    type="number"
                    name="luasTanah"
                    value={editData.luasTanah}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {/* Luas Bangunan */}
                <Form.Group controlId="formLuasBangunan">
                  <Form.Label>Luas Bangunan Rumah (m2)</Form.Label>
                  <Form.Control
                    type="number"
                    name="luasBangunan"
                    value={editData.luasBangunan}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {/* Jenis Atap */}
                <Form.Group controlId="formJenisAtap">
                  <Form.Label>Jenis Atap </Form.Label>
                  <Form.Select
                    name="jenisAtap"
                    value={editData.jenisAtap}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Beton</option>
                    <option value="2">2. Genteng</option>
                    <option value="3">3. Asbes/Seng</option>
                    <option value="4">4. Kayu/Sirap</option>
                    <option value="5">5. Bambu</option>
                    <option value="6">6. Jerami/ijuk/rumbia/daun-daunan</option>
                    <option value="7">7. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Jenis Dinding */}
                <Form.Group controlId="formJenisDinding">
                  <Form.Label>Jenis Dinding </Form.Label>
                  <Form.Select
                    name="jenisDinding"
                    value={editData.jenisDinding}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Tembok</option>
                    <option value="2">2. Kayu/Papan</option>
                    <option value="3">3. Seng</option>
                    <option value="4">4. Bambu</option>
                    <option value="5">5. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Jenis Lantai */}
                <Form.Group controlId="formJenisLantai">
                  <Form.Label>Jenis Lantai </Form.Label>
                  <Form.Select
                    name="jenisLantai"
                    value={editData.jenisLantai}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Keramik/Granit/Marmer/Ubin/Tegel/Teraso</option>
                    <option value="2">2. Semen</option>
                    <option value="3">3. Kayu/Papan</option>
                    <option value="4">4. Bambu</option>
                    <option value="5">5. Tanah</option>
                    <option value="6">6. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Penerangan */}
                <Form.Group controlId="formPenerangan">
                  <Form.Label>Penerangan</Form.Label>
                  <Form.Select
                    name="penerangan"
                    value={editData.penerangan}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Listrik Pribadi 900 Watt</option>
                    <option value="2">2. Listrik Pribadi diatas 900 Watt</option>
                    <option value="3">3. Genset/Solar Cell</option>
                    <option value="4">4. Listrik Bersama</option>
                    <option value="5">5. Non Listrik</option>
                  </Form.Select>
                </Form.Group>

                {/* Air Minum */}
                <Form.Group controlId="formAirMinum">
                  <Form.Label>Air Minum</Form.Label>
                  <Form.Select
                    name="airMinum"
                    value={editData.airMinum}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Air Kemasan/Isi Ulang</option>
                    <option value="2">2. Ledeng/PAM</option>
                    <option value="3">3. Sumur BOR</option>
                    <option value="4">4. Sumur Terlindungi</option>
                    <option value="5">5. Sumur Tidak Terlindungi</option>
                    <option value="6">6. Air Permukaan (Sungai, Danau, dll)</option>
                    <option value="7">7. Air Hujan</option>
                    <option value="8">8. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Jenis Kloset */}
                <Form.Group controlId="formJenisKloset">
                  <Form.Label>Jenis Kloset</Form.Label>
                  <Form.Select
                    name="jenisKloset"
                    value={editData.jenisKloset}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya dengan Septic Tank</option>
                    <option value="2">2. Ya tanpa Septic Tank</option>
                    <option value="3">3. Tidak Jamban Umum/Bersama</option>
                    <option value="4">4. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Bahan Bakar Memasak */}
                <Form.Group controlId="formBahanBakar">
                  <Form.Label>Bahan Bakar Memasak </Form.Label>
                  <Form.Select
                    name="bbMemasak"
                    value={editData.bbMemasak}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Listrik/Gas</option>
                    <option value="2">2. Minyak Tanah</option>
                    <option value="3">3. Arang/Kayu</option>
                    <option value="4">4. Lainnya</option>
                  </Form.Select>
                </Form.Group>

                {/* Kelayakan Konstruksi */}
                <Form.Group controlId="formKelayakanKonstruksi">
                  <Form.Label>Kelayakan Konstruksi</Form.Label>
                  <Form.Select
                    name="layakKonstruksi"
                    value={editData.layakKonstruksi}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                {/* Kelayakan Kepadatan Ruang */}
                <Form.Group controlId="formKepadatanRuang">
                  <Form.Label>Kelayakan Kepadatan Ruang (Densitas)</Form.Label>
                  <Form.Select
                    name="layakDensitas"
                    value={editData.layakDensitas}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                {/* Kelayakan Air Minum */}
                <Form.Group controlId="formKelayakanAirMinum">
                  <Form.Label>Kelayakan Air Minum</Form.Label>
                  <Form.Select
                    name="layakAirminum"
                    value={editData.layakAirminum}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                {/* Kelayakan Sanitasi */}
                <Form.Group controlId="formKelayakanSanitasi">
                  <Form.Label>Kelayakan Sanitasi</Form.Label>
                  <Form.Select
                    name="layakSanitasi"
                    value={editData.layakSanitasi}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                {/* Memiliki Aset Lahan di Lokasi Lain */}
                <Form.Group controlId="formAsetLahan">
                  <Form.Label>Memiliki Aset Lahan di Lokasi Lain</Form.Label>
                  <Form.Select
                    name="asetLahanlain"
                    value={editData.asetLahanlain}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                {/* Memiliki Rumah di Lokasi Lain */}
                <Form.Group controlId="formRumahLain">
                  <Form.Label>Memiliki Rumah di Lokasi Lain</Form.Label>
                  <Form.Select
                    name="asetRumahlain"
                    value={editData.asetRumahlain}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </Tab>

            <Tab eventKey="fourth" title="Peminatan Program">
              <Form>
                {/* Minat Program Pembiayaan Perumahan */}
                <Form.Group controlId="formMinatPembiayaan">
                  <Form.Label>Minat Program Pembiayaan Perumahan</Form.Label>
                  <Form.Select
                    name="minatProgrampembiayaan"
                    value={editData.minatProgrampembiayaan}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                {/* Jenis Peminatan Rumah */}
                <Form.Group controlId="formJenisPeminatanRumah">
                  <Form.Label>Jenis Peminatan Rumah</Form.Label>
                  <Form.Select
                    name="jenisPeminatanrumah"
                    value={editData.jenisPeminatanrumah}
                    onChange={handleInputChange}
                    disabled={editData.minatProgrampembiayaan === "2"}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Milik</option>
                    <option value="2">2. Sewa</option>
                  </Form.Select>
                </Form.Group>

                {/* Pilih Program Perumahan */}
                <Form.Group controlId="formPilihProgramPerumahan">
                  <Form.Label>Pilih Program Perumahan </Form.Label>
                  <Form.Select
                    name="programPerumahan"
                    value={editData.programPerumahan}
                    onChange={handleInputChange}
                    disabled={editData.minatProgrampembiayaan === "2"}
                  >
                    <option value="">Pilih Opsi</option>
                    {editData.jenisPeminatanrumah === "2" ? (
                      <>
                        <option value="4">Sewa Hunian</option>
                      </>
                    ) : editData.jenisPeminatanrumah === "1" ? (
                      <>
                        <option value="1">Pembiayaan Pemilikan Rumah</option>
                        <option value="2">Pembiayaan Pembangunan Rumah</option>
                        <option value="3">Pembiayaan Perbaikan Rumah</option>
                        <option value="5">Pembiayaan Pemilikan Rusun</option>
                      </>
                    ) : (
                      <option value="">Pilih Opsi</option>
                    )}
                  </Form.Select>
                </Form.Group>

                {/* Pilih Bank Pelaksana */}
                <Form.Group controlId="formPilihBankPelaksana">
                  <Form.Label>Pilih Bank Pelaksana</Form.Label>
                  <Form.Select
                    name="pilihanBankpelaksana"
                    value={editData.pilihanBankpelaksana}
                    onChange={handleInputChange}
                    disabled={editData.minatProgrampembiayaan === "2"}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Bank BTN</option>
                    <option value="2">2. Bank BRI</option>
                    <option value="3">3. Bank Lainnya sebutkan</option>
                  </Form.Select>
                </Form.Group>

                {/* Input Box for Other Bank Name */}
                {editData.pilihanBankpelaksana === "3" && (
                  <Form.Group controlId="formNamaBankLain">
                    <Form.Label>Nama Bank Lain</Form.Label>
                    <Form.Control
                      type="text"
                      name="namaBankLain"
                      value={editData.namaBankLain}
                      onChange={handleInputChange}
                      placeholder="Sebutkan nama bank lain"
                    />
                  </Form.Group>
                )}

                {/* Kesediaan Besar Cicilan */}
                <Form.Group controlId="formCicilan">
                  <Form.Label>Kemampuan Mencicil</Form.Label>
                  <Form.Select
                    name="besaranCicilan"
                    value={editData.besaranCicilan}
                    onChange={handleInputChange}
                    disabled={editData.minatProgrampembiayaan === "2"}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">&lt; 500rb</option>
                    <option value="2">500 ribu - 750 ribu</option>
                    <option value="3">750 ribu - 1 juta</option>
                    <option value="4">&gt; 1 juta</option>
                  </Form.Select>
                </Form.Group>

                {/* Rencana Program */}
                <Form.Group controlId="formRencanaProgram">
                  <Form.Label>Rencana Program</Form.Label>
                  <Form.Select
                    name="rencanaProgram"
                    value={editData.rencanaProgram}
                    onChange={handleInputChange}
                    disabled={editData.minatProgrampembiayaan === "2"}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">2025</option>
                    <option value="2">2026</option>
                    <option value="3">2027</option>
                    <option value="4">Belum Tahu Kapan</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            </Tab>


            <Tab eventKey="fifth" title="Kawasan Iklim">
              <Form>
                {/* Sarana Transportasi Umum */}
                <Form.Group controlId="formSaranaTransportasi">
                  <Form.Label>Sarana Transportasi</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Angkutan Umum"
                    name="angkutanUmum"
                    checked={editData.angkutanUmum}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Terminal"
                    name="terminal"
                    checked={editData.terminal}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Stasiun"
                    name="stasiun"
                    checked={editData.stasiun}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Pasar"
                    name="pasar"
                    checked={editData.pasar}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Bank"
                    name="bank"
                    checked={editData.bank}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Gerbang Tol"
                    name="gerbangTol"
                    checked={editData.gerbangTol}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="SPBU"
                    name="spbu"
                    checked={editData.spbu}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {/* Sarana Pendidikan */}
                <Form.Group controlId="formSaranaPendidikan">
                  <Form.Label>Sarana Pendidikan</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="TK"
                    name="tk"
                    checked={editData.tk}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="SD"
                    name="sd"
                    checked={editData.sd}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="SLTP"
                    name="sltp"
                    checked={editData.sltp}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="SLTA"
                    name="slta"
                    checked={editData.slta}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="universitas"
                    name="universitas"
                    checked={editData.universitas}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Lainnya"
                    name="pendidikanLainnya"
                    checked={editData.pendidikanLainnya}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {/* Sarana Tempat Ibadah */}
                <Form.Group controlId="formSaranaIbadah">
                  <Form.Label>Sarana Tempat Ibadah</Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Masjid/Musholla"
                    name="masjidMusholla"
                    checked={editData.masjidMusholla}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Gereja"
                    name="gereja"
                    checked={editData.gereja}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Vihara"
                    name="vihara"
                    checked={editData.vihara}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Klenteng"
                    name="klenteng"
                    checked={editData.klenteng}
                    onChange={handleInputChange}
                  />
                  <Form.Check
                    type="checkbox"
                    label="Lainnya"
                    name="tempatIbadahLainnya"
                    checked={editData.tempatIbadahLainnya}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                {/* Kondisi Iklim dalam 3 Tahun Terakhir */}
                <Form.Group controlId="formGenanganAir">
                  <Form.Label>Apakah pernah terjadi Genangan Air</Form.Label>
                  <Form.Select
                    name="terjadiGenanganAir"
                    value={editData.terjadiGenanganAir}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formBanjir">
                  <Form.Label>Apakah pernah terjadi Banjir </Form.Label>
                  <Form.Select
                    name="terjadiBanjir"
                    value={editData.terjadiBanjir}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formPutingBeliung">
                  <Form.Label>Apakah pernah terjadi Angin Puting Beliung</Form.Label>
                  <Form.Select
                    name="terjadiPutingBeliung"
                    value={editData.terjadiPutingBeliung}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formKeretakanTanah">
                  <Form.Label>Apakah pernah terjadi Keretakan Tanah</Form.Label>
                  <Form.Select
                    name="terjadiKeretakanTanah"
                    value={editData.terjadiKeretakanTanah}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formLongsor">
                  <Form.Label>Apakah pernah terjadi Longsor </Form.Label>
                  <Form.Select
                    name="terjadiLongsor"
                    value={editData.terjadiLongsor}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group controlId="formGempaBumi">
                  <Form.Label>Apakah pernah terjadi Gempa Bumi </Form.Label>
                  <Form.Select
                    name="terjadiGempaBumi"
                    value={editData.terjadiGempaBumi}
                    onChange={handleInputChange}
                  >
                    <option value="">Pilih Opsi</option>
                    <option value="1">1. Ya</option>
                    <option value="2">2. Tidak</option>
                  </Form.Select>
                </Form.Group>
              </Form>


            </Tab>

            <Tab eventKey="sixth" title="Photo">
              <Form>
                <Row>
                  <Col md={6}>
                    {/* Photo Diri */}
                    <Form.Group controlId="formPhotoDiri">
                      <Form.Label>Photo Diri</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="photoDiri"
                        onChange={(e) => handleImageChange(e, 'photoDiri')}
                      />
                      <img
                        src={previewImages.photoDiri}
                        alt="Preview Photo Diri"
                        style={{ width: '250px', marginTop: '10px' }}
                      />
                      {geoData.photoDiri ? (
                        <p>Location: {geoData.photoDiri.lon}, {geoData.photoDiri.lat}</p>
                      ) : (
                        editData.koordinatFotoDiri && <p>Location: {editData.koordinatFotoDiri}</p>
                      )}

                    </Form.Group>

                    {/* Photo Sekitar */}
                    <Form.Group controlId="formPhotoSekitar">
                      <Form.Label>Photo Sekitar</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="photoSekitar"
                        onChange={(e) => handleImageChange(e, 'photoSekitar')}
                      />
                      <img
                        src={previewImages.photoSekitar}
                        alt="Preview Photo Sekitar"
                        style={{ width: '250px', marginTop: '10px' }}
                      />
                      {geoData.photoSekitar ? (
                        <p>Location: {geoData.photoSekitar.lon}, {geoData.photoSekitar.lat}</p>
                      ) : (
                        editData.koordinatFotoSekitar && <p>Location: {editData.koordinatFotoSekitar}</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    {/* Photo Rumah */}
                    <Form.Group controlId="formPhotoRumah">
                      <Form.Label>Photo Rumah</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="photoRumah"
                        onChange={(e) => handleImageChange(e, 'photoRumah')}
                      />
                      <img
                        src={previewImages.photoRumah}
                        alt="Preview Photo Rumah"
                        style={{ width: '250px', marginTop: '10px' }}
                      />
                      {geoData.photoRumah ? (
                        <p>Location: {geoData.photoRumah.lon}, {geoData.photoRumah.lat}</p>
                      ) : (
                        editData.koordinatFotoRumah && <p>Location: {editData.koordinatFotoRumah}</p>
                      )}
                    </Form.Group>

                    {/* Photo Lahan */}
                    <Form.Group controlId="formPhotoLahan">
                      <Form.Label>Photo Lahan</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="photoLahan"
                        onChange={(e) => handleImageChange(e, 'photoLahan')}
                      />
                      <img
                        src={previewImages.photoLahan}
                        alt="Preview Photo Lahan"
                        style={{ width: '250px', marginTop: '10px' }}
                      />
                      {geoData.photoLahan ? (
                        <p>Location: {geoData.photoLahan.lon}, {geoData.photoLahan.lat}</p>
                      ) : (
                        editData.koordinatFotoLahan && <p>Location: {editData.koordinatFotoLahan}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>

          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          {!viewOnly && (
            <Button variant="primary" onClick={handleModalSave} disabled={viewOnly || loading}>
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                  Simpan...
                </>
              ) : (
                'Update Data'
              )}
            </Button>
          )}
        </Modal.Footer>

      </Modal>


      {isModalUpload && <ModalUploadCSV onClose={handleModalUploadClose} editingData={editingData} />}
    </>


  )
}

export default UploadRumahquCsv
