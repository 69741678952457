import React, { useState, useEffect } from 'react';

import { Modal, Button, Form } from 'react-bootstrap';

import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import { useNavigate } from 'react-router-dom';
import EXIF from 'exif-js';


const GeoTagTest = ({ show, handleClose, onChangePassword }) => {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');


  let refreshToken = null;
  const { user } = useContext(AuthContext);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [setIsTokenExpired] = useState(false);


  const [geoData, setGeoData] = useState({
    photoDiri: null,
    photoSekitar: null,
    photoRumah: null,
    photoLahan: null
  });


  const [selectedFiles, setSelectedFiles] = useState({
    photoDiri: null,
    photoSekitar: null,
    photoRumah: null,
    photoLahan: null
  });

  const [previewImages, setPreviewImages] = useState({});
      const [editData, setEditData] = useState({ id: '', nik: '', namaLengkap: '', handphone: '' });
  


  const handleImageChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
      if (fileSize > 10) {
        alert("Ukuran file tidak boleh lebih dari 10MB");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFiles(prevState => ({
          ...prevState,
          [fieldName]: file
        }));
        setPreviewImages(prevState => ({
          ...prevState,
          [fieldName]: reader.result // Store the preview image data URL
        }));
      };
      reader.readAsDataURL(file); // Read the file as a data URL

      EXIF.getData(file, function () {
        const latitude = EXIF.getTag(this, 'GPSLatitude');
        const longitude = EXIF.getTag(this, 'GPSLongitude');
        const latitudeRef = EXIF.getTag(this, 'GPSLatitudeRef');
        const longitudeRef = EXIF.getTag(this, 'GPSLongitudeRef');

        if (latitude && longitude) {
          const lat = convertDMSToDD(latitude, latitudeRef);
          const lon = convertDMSToDD(longitude, longitudeRef);

          // Update geoData with the geolocation information from EXIF
          setGeoData(prevState => ({
            ...prevState,
            [fieldName]: {
              lat,
              lon,
              latitudeRef,
              longitudeRef
            }
          }));

          console.log('Geo data from EXIF:');
          console.log(geoData);
        } else {
          // Jika EXIF tidak mengandung geotag, minta lokasi pengguna
          alert('Foto ini tidak mengandung informasi geotag. Menggunakan lokasi pengguna...');
          getUserLocation(fieldName); // Memanggil fungsi untuk mendapatkan lokasi pengguna
        }
      });
    }
  };

  // Fungsi untuk mengonversi koordinat DMS (Degrees, Minutes, Seconds) ke Decimal Degrees (DD)
  const convertDMSToDD = (dms, ref) => {
    const degrees = dms[0] + dms[1] / 60 + dms[2] / 3600;
    return ref === 'S' || ref === 'W' ? -degrees : degrees;
  };

  // Fungsi untuk meminta lokasi pengguna melalui Geolocation API
  const getUserLocation = (fieldName) => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          console.log("User Location: Latitude = " + latitude + ", Longitude = " + longitude);

          // Update geoData dengan lokasi yang didapat dari API Geolocation
          setGeoData(prevState => ({
            ...prevState,
            [fieldName]: {
              lat: latitude,
              lon: longitude
            }
          }));
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              alert("Pengguna menolak permintaan lokasi.");
              break;
            case error.POSITION_UNAVAILABLE:
              alert("Lokasi tidak tersedia.");
              break;
            case error.TIMEOUT:
              alert("Permintaan lokasi gagal karena waktu habis.");
              break;
            case error.UNKNOWN_ERROR:
              alert("Terjadi kesalahan yang tidak diketahui.");
              break;
            default:
              alert("Terjadi kesalahan.");
          }
        }
      );
    } else {
      alert("Geolocation tidak didukung di browser ini.");
    }
  };


  useEffect(() => {
    setGeoData({}); // Reset geolocation data for image uploads
        setPreviewImages({
            photoDiri: "/dist/img/no-photo-choose.png",
            photoSekitar: "/dist/img/no-photo-choose.png",
            photoRumah: "/dist/img/no-photo-choose.png",
            photoLahan: "/dist/img/no-photo-choose.png"
        });
  }, []);



  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Tes Kelengkapan Koordinat pada sebuah Photo</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form.Group controlId="formPhotoDiri">
          <Form.Label>Photo</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            name="photoDiri"
            onChange={(e) => handleImageChange(e, 'photoDiri')}
          />
          <img
            src={previewImages.photoDiri}
            alt="Preview Photo Diri"
            style={{ width: '250px', marginTop: '10px' }}
          />
          {geoData.photoDiri ? (
            <p>Location: {geoData.photoDiri.lon}, {geoData.photoDiri.lat}</p>
          ) : (
            editData.koordinatFotoDiri && <p>Location: {editData.koordinatFotoDiri}</p>
          )}

        </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );

};

export default GeoTagTest;
