import React from 'react'
import { useState } from 'react';
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import FooterQuestion from './FooterQuestion';
import { useLanguage } from '../../context/LanguageContext';
// import { useState } from 'react';


const IgahpDetail = () => {
  const { translations } = useLanguage(); // Mengambil terjemahan dari konteks bahasa
  const myRegulasiTranslations = translations?.myRegulasiTranslations || {};
  const myLandingTranslations = translations?.mylanding || {};

  // const content accordion content
  const [isActivePenilaian, setIsActivePenilaian] = useState(false);
  const handlePenilaianOtomatisClick = () => {
    setIsActivePenilaian(!isActivePenilaian);
    setIsActiveUnggah(false);
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveUnggah, setIsActiveUnggah] = useState(false);
  const handleUnggahOtomatisClick = () => {
    setIsActiveUnggah(!isActiveUnggah);
    setIsActivePenilaian(false)
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveTerbit, setIsActiveTerbit] = useState(false);
  const handleTerbitOtomatisClick = () => {
    setIsActiveTerbit(!isActiveTerbit)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
    setIsActivePenugasan(false);
  };


  const [isActivePenugasan, setIsActivePenugasan] = useState(false);
  const handlePenugasanOtomatisClick = () => {
    setIsActivePenugasan(!isActivePenugasan)
    setIsActiveTerbit(false)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
  };


  require("./../../css/common.css");

  return (

    <>

      <div>

        <NavBarAtas page="4" showButton={true} />

        <section className="body-home-content-regulasi">

          <div className="section-headlines-header-text">

            <div className="wrapper-label-headlines">
              <div className="igahp-highlight-semi-bold">
                {myLandingTranslations.regulationTitle ||
                  "Regulasi"}
              </div>
              <div className="igahp-l-regular black-text">
                {myLandingTranslations.regulationDescription ||
                  "Kumpulan peraturan perundang-undangan terkait."}
              </div>
            </div>

            {/* <div className="wrapper-house-information">
              <div className="igahp-l-regular black-text">Ciptakan hunian berwawasan lingkungan dengan Lentera HIjau Mobile</div>
              <div className="igahp-l-regular black-text"><p>1. Telah terintegrasi langsung dengan SIMBG</p></div>

            </div> */}


          </div>

          <section className="section-body-maxpage">
            <div className="body-card">

              {/* <img src="images/aplikasi/image_phone_aplikasi_1.svg " id="image-keunggulan-aplikasi" /> */}
              {/* <img src={imageSource} id="image-keunggulan-aplikasi" /> */}

              <div className="wrapper-deskripsi-faq-regulasi">

                <div className="wrapper-list-faq-regulasi">

                  <div className={`list-faq-regulasi-item ${isActivePenilaian ? 'active' : ''}`} id="penilaian-otomatis" onClick={handlePenilaianOtomatisClick}>
                    <div className="list-faq-regulasi-title">Peraturan Pemerintah No 16 Tahun 2021</div>
                    <div className="list-faq-regulasi-description">
                      Peraturan Pemerintah No 16 Tahun 2021 tentang Peraturan Pelaksanaan Undang-Undang Nomor 28 Tahun 2002 tentang Bangunan Gedung
                      <div className="btn-container">
                        <div className="btn-unduh-file igahp-s-medium">Unduh file
                          <img src="/ic_download_file.svg" alt=""></img></div>
                      </div>
                    </div>
                    {/* <img src={imageSource} id="image-keunggulan-aplikasi-mobile" /> */}
                    <div className="divider-neutral-30"></div>
                    <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" alt="" width={20} height={20} />

                  </div>

                  <div className={`list-faq-regulasi-item ${isActiveUnggah ? 'active' : ''}`} id="unggah-dokumen" onClick={handleUnggahOtomatisClick}>
                    <div className="list-faq-regulasi-title">Peraturan Menteri PUPR No 21 Tahun 2021</div>
                    <div className="list-faq-regulasi-description">
                      Peraturan Menteri Pekerjaan Umum dan Perumahan Rakyat Nomor 21 Tahun 2021 tentang Penilaian Kinerja Bangunan Gedung Hijau
                      <div className="btn-container">
                        <div className="btn-unduh-file igahp-s-medium">Unduh file
                          <img src="/ic_download_file.svg" alt=""></img></div>
                      </div>
                    </div>
                    {/* <img src={imageSource} id="image-keunggulan-aplikasi-mobile" /> */}
                    <div className="divider-neutral-30" />
                    <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" alt="" width={20} height={20} />
                  </div>

                  <div className={`list-faq-regulasi-item ${isActiveTerbit ? 'active' : ''}`} id="penerbitan-dokumen" onClick={handleTerbitOtomatisClick}>
                    <div className="list-faq-regulasi-title">Surat Edaran Direktur Jenderal Cipta Karya Kementerian PUPR No 03/SE/DC/2023</div>
                    <div className="list-faq-regulasi-description">
                      Petunjuk Teknis Penilaian Kinerja Bangunan Gedung Hijau untuk Klas Bangunan 1a
                      <div className="btn-container">
                        <div className="btn-unduh-file igahp-s-medium">Unduh file
                          <img src="/ic_download_file.svg" alt=""></img></div>
                      </div>
                    </div>
                    <div className="divider-neutral-30" />
                    <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" alt="" width={20} height={20} />
                  </div>

                </div>
              </div>
            </div>
            <div className="body-card">
              <div className="container-attention">
                <div className="content-attention">
                  <div className="container-text-attention">
                    <div className="label-text-attention">
                      {myLandingTranslations.reportLabel || "LAPORKAN!!"}
                    </div>
                    <div className="support-text-attention">
                      <p>
                        {myLandingTranslations.reportDescription1 ||
                          "Jika anda melihat dan mendengar Pelanggaran/Kecurangan yang dilakukan oleh Pejabat/Pegawai di lingkungan"}
                      </p>
                      <p>
                        {myLandingTranslations.reportDescription2 ||
                          "Kementerian Perumahan dan Kawasan Permukiman"}
                      </p>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </section>

        </section>

        <FooterQuestion />
        <FooterFront />
      </div>

    </>



  )
};

export default IgahpDetail
