import React from 'react'
import { useEffect } from 'react';
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    // Link,
    Outlet,
} from "react-router-dom";

import { useState } from 'react';
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PictureProfile from '../PictureProfile';

const SidebarBank = () => {


    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const navigate = useNavigate()

    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [data, setData] = useState({});

    let refreshToken = null;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/aktifmenu.js";
        script.async = true;
        document.body.appendChild(script);

        // fetchData();

        return () => { script.remove(); }
    }, []);


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            console.log('user id ' + user.id);
            console.log('pengembang id ' + user.pengembang_id);
            var pengembangId = '';
            const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + user.pengembang_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            console.log(response.data);
            setData(response.data);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    return (
        <>

            <div>

                {/* Main Sidebar Container */}
                <aside className="main-sidebar sidebar-light-success elevation-4 position-fixed">
                    {/* Brand Logo */}
                    <a href="/" className="brand-link">
                    <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                    <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA HIJAU</b></h4>
                </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        {/* Sidebar user panel (optional) */}
                        <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                            <div className="image">
                                {/* <img src="/dist/img/user1-128x128.jpg" className="img-circle elevation-2" alt="image" /> */}
                                <PictureProfile/>
                            </div>
                            <div className="info p-0 ml-4">
                        <div>
                            <info className="text-muted">
                                    USER BANK
                            <a href="#" className="d-block">{data.pic}</a>
                            </info>
                            </div>
                            {user.username}
                        </div>
                        </div>
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


                                {/* <li className="nav-item">
                                    <a href="index.html" className="nav-link">
                                        <i className="nav-icon fas fa-tachometer-alt" />
                                        <p>
                                            Dashboard
                                        </p>
                                    </a>
                                </li> */}

                                <li className="nav-item">
                                    <Link to="bank/dashboard" className="nav-link">
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>Dashboard</p>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="bank/BankDataSLik" className="nav-link">
                                        <i className="nav-icon fas fa-house-user"></i>
                                        <p>Data Pengajuan SLIK SLIK</p>
                                    </Link>
                                </li>

                                {/* <li className="nav-item">
                                    <Link to="bank/bi-checking" className="nav-link">
                                        <i className="nav-icon fas fa-house-user"></i>
                                        <p>Set BI Checking</p>
                                    </Link>
                                </li> */}


                                {/* <li className="nav-item">
                                    <Link to="bank/BankProfile" className="nav-link">
                                        <i className="nav-icon fas fa-user "></i>
                                        <p>Profile bank</p>
                                    </Link>
                                </li> */}

                                {/* <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        <i class="nav-icon fas fa-house-user"></i>
                                        <p>
                                            Perumahan
                                            <i class="fas fa-angle-left right"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="pengembang/PengembangLokasiTambah" className="nav-link">
                                                <i className="nav-icon far fa-circle text-danger"></i>
                                                <p>Tambah Perumahan</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="pengembang/PengembangAdminListLokasi" className="nav-link">
                                                <i className="nav-icon far fa-circle text-warning"></i>
                                                <p>Info Perumahan</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li> */}


                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>


        </>
    )
}

export default SidebarBank
