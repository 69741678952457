import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import ChangePasswordModal from "../shared/ChangePasswordModal"
import GeoTagTest from '../shared/GeoTagTest';

const Sidebar = () => {
    const { user } = useContext(AuthContext);

    const [showModalPassword, setshowModalPassword] = useState(false);

    const [showModalGeo, setshowModalGeo] = useState(false);

    // Fungsi untuk menampilkan modal
    const handleshowModalPassword = () => setshowModalPassword(true);

    const handleshowModalGeo = () => setshowModalGeo(true);


    // Fungsi untuk menutup modal
    const handleCloseModalPassword = () => setshowModalPassword(false);

    const handleCloseModalGeo = () => setshowModalGeo(false);

    // State to handle the collapse of each section
    const [isCollapsed, setIsCollapsed] = useState({
        dataReferensi: false,
        calonPenerima: false,
        surveiLapangan: false,
        minatProgram: false,
        slikBank: false,
        penerimaManfaat: false,
        pelaporan: false,
        hasilSubsidiChecking: false, // Added for handling submenu under Hasil Subsidi Checking
    });

    // Function to toggle collapse of a specific menu
    const toggleCollapse = (section) => {
        setIsCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    useEffect(() => {
        // Any additional JavaScript can be loaded here
    }, []);

    return (
        <aside id="control_sidebar" className="main-sidebar sidebar-light-success elevation-4">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                <h4>
                    <span className="brand-text text-muted ml-2"></span>
                    <b>LENTERA HIJAU</b>
                </h4>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                    {/* <div className="image">
                        <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                    </div> */}
                    <div className="info p-0 ml-4">
                        <div>
                            <span className="text-muted">Enumerator</span>
                            <a href="#" className="d-block">{user?.username}</a>
                        </div>
                    </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Dashboard */}
                        <li className="nav-item">
                            <Link to="surveyor/SurveyorDashboard" className="nav-link">
                                <i className="bi bi-speedometer2 nav-icon mr-2"></i>
                                <p>Dashboard</p>
                            </Link>
                        </li>

                        {/* Data Survei Lapangan */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('surveiLapangan')}>
                                <i className="bi bi-folder nav-icon mr-2"></i>
                                <p>
                                    Data Survei Lapangan
                                    <i className={`bi bi-chevron-down right ${isCollapsed.surveiLapangan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.surveiLapangan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="surveyor/SurveyorDataSiapSurvei" className="nav-link">
                                            <i className="bi bi-record-circle-fill text-danger nav-icon mr-2"></i>
                                            <p>Data Siap Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="surveyor/SurveyorHasilSurvei" className="nav-link">
                                            <i className="bi bi-record-circle-fill text-info nav-icon mr-2"></i>
                                            <p>Data Hasil Survei</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="surveyor/SurveyorAnalisaSurvei" className="nav-link">
                                            <i className="bi bi-record-circle-fill text-success nav-icon mr-2"></i>
                                            <p>Data Hasil Analisa Survei</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Pelaporan */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('pelaporan')}>
                                <i className="bi bi-file-earmark-bar-graph nav-icon mr-2"></i>
                                <p>
                                    Pelaporan
                                    <i className={`bi bi-chevron-down right ${isCollapsed.pelaporan ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.pelaporan && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="surveyor/RptPerformance1" className="nav-link">
                                            <i className="bi bi-graph-up nav-icon text-danger mr-2"></i>
                                            <p>Monitoring Enumerator</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="surveyor/RptAlamat" className="nav-link">
                                            <i className="bi bi-map nav-icon text-primary mr-2"></i>
                                            <p>Monitoring Alamat</p>
                                        </Link>
                                    </li>
                                </ul>
                            )}
                        </li>

                        {/* Utility */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('utility')}>
                                <i className="bi bi-tools nav-icon mr-2"></i>
                                <p>
                                    Utility
                                    <i className={`bi bi-chevron-down right ${isCollapsed.utility ? 'rotate' : ''}`}></i>
                                </p>
                            </a>
                            {isCollapsed.utility && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link" onClick={handleshowModalGeo}>
                                        <i className="bi bi-camera-fill nav-icon text-blue fs-3 mr-2"></i>
                                            <p>Tes Photo Geotag</p>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                                            <i className="bi bi-key-fill nav-icon text-blue mr-2"></i>
                                            <p>Rubah Password</p>
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <ChangePasswordModal
                            show={showModalPassword}
                            handleClose={handleCloseModalPassword}
                        />
                        <GeoTagTest
                            show={showModalGeo}
                            handleClose={handleCloseModalGeo}
                        />
                    </ul>
                </nav>
            </div>
        </aside>
    );

};

export default Sidebar;
