import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';


import ChangePasswordModal from "../shared/ChangePasswordModal"

const Sidebar = () => {
    const { user } = useContext(AuthContext);

    const [showModalPassword, setshowModalPassword] = useState(false);

    // Fungsi untuk menampilkan modal
    const handleshowModalPassword = () => setshowModalPassword(true);

    // Fungsi untuk menutup modal
    const handleCloseModalPassword = () => setshowModalPassword(false);

    // State untuk menangani collapse
    const [isCollapsed, setIsCollapsed] = useState({
        datapemohon: false,
        utility: false,
    });

    // Function untuk toggle collapse
    const toggleCollapse = (section) => {
        setIsCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    };

    useEffect(() => {
        // Any additional JavaScript can be loaded here
    }, []);

    return (
        <aside id="control_sidebar" className="main-sidebar sidebar-light-success elevation-3">
            {/* Brand Logo */}
            <a href="/" className="brand-link">
                <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                <h4>
                    <span className="brand-text text-muted ml-2"></span>
                    <b>LENTERA HIJAU</b>
                </h4>
            </a>

            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user panel */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                    {/* <div className="image">
                        <img src="/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User" />
                    </div> */}
                    <div className="info p-0 ml-3">
                        <div>
                            <info className="text-muted">
                                PENERIMA MANFAAT
                                <a href="#" className="d-block">Mulyana Fauzi</a>
                            </info>
                        </div>
                    </div>
                </div>

                {/* Sidebar Menu */}
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {/* Dashboard */}
                      
                        {/* Profil Pemohon */}
                        <li className="nav-item">
                            <Link to="penerima/ProfileDokumenPenerimaManfaat" className="nav-link">
                                <i className="bi bi-person-badge nav-icon mr-2"></i>
                                <p>Data Pemohon</p>
                            </Link>
                        </li>

                        {/* Utility */}
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={() => toggleCollapse('utility')}>
                                <i className="nav-icon bi bi-person-lock mr-2"></i>
                                <p>
                                    Kata Sandi
                                    <i className={`bi bi-chevron-${isCollapsed.utility ? 'right' : 'down'} right`}></i>
                                </p>
                            </a>
                            {isCollapsed.utility && (
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <a href="#" className="nav-link" onClick={handleshowModalPassword}>
                                            <i className="bi bi-shield-lock nav-icon text-blue mr-2"></i>
                                            <p>Rubah Password</p>
                                        </a>
                                    </li>
                                </ul>
                            )}
                        </li>

                        <ChangePasswordModal
                            show={showModalPassword}
                            handleClose={handleCloseModalPassword}
                        // onChangePassword={handleChangePassword}
                        />
                    </ul>
                </nav>
            </div>
        </aside>
    );
};

export default Sidebar;
