import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import Login from './Login';
import Logout from './Logout';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import MainMenu from './MainMenu';
import PrivateRoute from './PrivateRoute';
import FrontWeb from './components/Front/FrontWeb';
import LandingPage from './componentsFront/pages/LandingPage'
import AboutUsPage from './componentsFront/pages/AboutUsPage'
import RumahHijauPage from './componentsFront/pages/RumahHijauPage'
import LoginPage from './componentsFront/pages/LoginPage'
import Mylanding from './components/Front/Mylanding';
import RumahHijau from './components/Front/RumahHijau';
import TentangKami from './components/Front/TentangKami';
import Panduan from './components/Front/Panduan';
import Sertifikasi from './components/Front/Sertifikasi';
import Regulasi from './components/Front/Regulasi';
import IgahpDetail from './components/Front/IgahpDetail';
import Product from './components/Front/Product';
import EmailVerification from './components/Front/EmailVerification';
import ComingSoon from './components/Front/ComingSoon';
import Catalog from './components/Front/Catalog';
import CatalogDetail from './components/Front/CatalogDetail';
import CatalogDetail2 from './components/Front/CatalogDetail2';
import PerumahanDetail from './components/Front/PerumahanDetail';
import RegisterNew from './components/Front/RegisterNew';
import Aplikasi from './components/Front/Aplikasi';
import Blog from './components/Front/Blog';
import BlogDetail from './components/Front/BlogDetail';
import BlogDetail2 from './components/Front/BlogDetail2';
import NewLanding from './components/Front/NewLanding';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';
import HomeCarousel from './components/Front/HomeCarousel';
import ResetPassword from './components/Front/ResetPassword';
import EnvProvider from './components/EnvProvider';
// import Product from './components/Front/Product';

const App = () => {
  return (
    <>
      <EnvProvider />
      {/* <Helmet>
        <link rel="icon" type="image/png" href="../src/logo.svg" sizes="16x16" />
      </Helmet> */}

      {
        <RecoilRoot>
          <AuthProvider>
            {/* <Router basename="/igahp"> */}
            <Router>
              <Routes>

                {/* <Route exact path="/" element={<NewLanding />} />               */}
                {/* <Route exact path="/igahp" element={<Mylanding />} /> */}
                <Route exact path="/" element={<Mylanding />} />

                <Route exact path="/OneGreen" element={<Mylanding />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/RumahHijau" element={<RumahHijau />} />
                <Route exact path="/TentangKami" element={<TentangKami />} />
                <Route exact path="/Panduan" element={<Panduan />} />
                <Route exact path="/Sertifikasi" element={<Sertifikasi />} />
                <Route exact path="/Regulasi" element={<Regulasi />} />
                <Route exact path="/IgahpDetail" element={<IgahpDetail />} />
                <Route exact path="/Product" element={<Product />} />

                <Route exact path="/carousel" element={<HomeCarousel />} />


                {/* <Route exact path="/login" element={<LoginPage />} /> */}

                <Route exact path="/aboutus" element={<AboutUsPage />} />
                <Route exact path="/rumahhijau" element={<RumahHijauPage />} />

                <Route exact path="/emailverify" element={<EmailVerification />} />

                <Route exact path="/reset-password" element={<ResetPassword />} />

                <Route exact path="/comingsoon" element={<ComingSoon />} />
                <Route exact path="/catalog" element={<Catalog />} />
                <Route exact path="/aplikasi" element={<Aplikasi />} />
                <Route exact path="/blog" element={<Blog />} />
                <Route exact path="/blogdetail" element={<BlogDetail />} />
                <Route exact path="/blogdetail2" element={<BlogDetail2 />} />


                <Route exact path="/catalogdetail" element={<CatalogDetail />} />
                <Route exact path="/catalogdetail2" element={<CatalogDetail2 />} />
                <Route exact path="/perumahandetail" element={<PerumahanDetail />} />

                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/register" element={<RegisterNew />} />
                {/* <Route path="/*" */}
                <Route path="/depan/*"
                  element={
                    <PrivateRoute>
                      <MainMenu />
                    </PrivateRoute>
                  } />
                <Route exact path="/logout"
                  element={
                    <PrivateRoute>
                      <Logout />
                    </PrivateRoute>
                  } />
                <Route exact path="/logout" element={<Logout />} />
              </Routes>
            </Router>
          </AuthProvider>
        </RecoilRoot>
      }
    </>
  );
};

export default App;