import React from 'react'

import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";


const Navbar = () => {
    return (
    <div>
      

            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
              {/* Left navbar links */}
              <ul className="navbar-nav">
                  <li className="nav-item">
                      <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                  </li>
              </ul>
              {/* Right navbar links */}
              <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                      <Link to="/logout" className="nav-link">
                          <i className="nav-icon fas fa-power-off"></i>
                      </Link>
                  </li>
                  <li className="nav-item">
                      <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                          <i className="fas fa-expand-arrows-alt" />
                      </a>
                  </li>
              </ul>
          </nav>
            {/* /.navbar */}


    </div>
  )
}

export default Navbar
